import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN, ROLE_BASED_DISPLAY } from '../config';
// hooks
import useLocales from '../hooks/useLocales';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { currentLang } = useLocales();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component  key={currentLang.value} {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    { path: 'login', element: (
      <GuestGuard>
        <Login2 />
      </GuestGuard>
    )},
    { path: 'signup', element: (
      <GuestGuard>
        <SignUp />
      </GuestGuard>
    )},
    { path: 'welcome', element: (
      <AuthGuard>
        <Welcome />
      </AuthGuard>
    )},

    // Letsmeet Routes
    {
      path: '*', // 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'overview', element: <GeneralOverview /> },
        { path: 'calendar', element: <GeneralCalendar /> },
        // { path: 'sales', element: <GeneralSales /> },
        {
          path: 'sales',
          children: [
            { element: <Navigate to="/sales/bookings" replace />, index: true },
            { path: 'bookings', element: <RoleBasedGuard accessibleRoles={ROLE_BASED_DISPLAY.BOOK}><GeneralSalesBookings /></RoleBasedGuard> },
            { path: 'coworking', element: <RoleBasedGuard accessibleRoles={ROLE_BASED_DISPLAY.WORK}><GeneralSalesCoworking /></RoleBasedGuard> },
          ],
        },
        // { path: 'customers', element: <GeneralCustomers /> },
        {
          path: 'customers',
          children: [
            { element: <Navigate to="/customers/bookings" replace />, index: true },
            { path: 'bookings', element: <RoleBasedGuard accessibleRoles={ROLE_BASED_DISPLAY.BOOK}><GeneralCustomersBookings /></RoleBasedGuard> },
            { path: 'coworking', element: <RoleBasedGuard accessibleRoles={ROLE_BASED_DISPLAY.WORK}><GeneralCustomersCoworking /></RoleBasedGuard> },
          ],
        },

        {
          path: 'my-places',
          children: [
            { element: <Navigate to="/dashboard/my-places/list" replace />, index: true },
            { path: 'list', element: <MyPlaces /> },
            { path: 'branch/new', element: <MyPlaceBranchCreate /> },
            { path: 'branch/:branchname/:branchid/edit', element: <MyPlaceBranchCreate /> },
            { path: 'branch/:branchname/:branchid/list', element: <RoleBasedGuard accessibleRoles={ROLE_BASED_DISPLAY.BOOK}><MyPlaces /></RoleBasedGuard> },
            { path: 'branch/:branchname/:branchid/space/new', element: <RoleBasedGuard accessibleRoles={ROLE_BASED_DISPLAY.BOOK}><MyPlaceSpaceCreate /></RoleBasedGuard> },
            { path: 'branch/:branchname/:branchid/space/:spacename/:spaceid/edit', element: <RoleBasedGuard accessibleRoles={ROLE_BASED_DISPLAY.BOOK}><MyPlaceSpaceCreate /></RoleBasedGuard> },
          ],
        },

        { path: 'settings', element: <AccountSettings /> },

      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    // default
    { path: '/', element: <Navigate to="/overview" replace /> },

    // Not found
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
// const Home = Loadable(lazy(() => import('../pages/auth/Home')));
const Welcome = Loadable(lazy(() => import('../pages/Welcome')));
const SignUp = Loadable(lazy(() => import('../pages/auth/SignUp')));
const Login2 = Loadable(lazy(() => import('../pages/auth/Login2')));
// const Login = Loadable(lazy(() => import('../pages/auth/Login')));
// const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
// const ChangePassword = Loadable(lazy(() => import('../pages/auth/ChangePassword')));
// Letsmeet Dashboard
const GeneralOverview = Loadable(lazy(() => import('../pages/dashboard/GeneralOverview')));
const GeneralCalendar = Loadable(lazy(() => import('../pages/dashboard/GeneralCalendar')));
// const GeneralSales = Loadable(lazy(() => import('../pages/dashboard/GeneralSalesBookings')));
const GeneralSalesBookings = Loadable(lazy(() => import('../pages/dashboard/GeneralSalesBookings')));
const GeneralSalesCoworking = Loadable(lazy(() => import('../pages/dashboard/GeneralSalesCoworking')));
const GeneralCustomersBookings = Loadable(lazy(() => import('../pages/dashboard/GeneralCustomersBookings')));
const GeneralCustomersCoworking = Loadable(lazy(() => import('../pages/dashboard/GeneralCustomersCoworking')));
const MyPlaces = Loadable(lazy(() => import('../pages/dashboard/MyPlaces')));
const MyPlaceBranchCreate = Loadable(lazy(() => import('../pages/dashboard/MyPlaceBranchCreate')));
const MyPlaceSpaceCreate = Loadable(lazy(() => import('../pages/dashboard/MyPlaceSpaceCreate')));
const AccountSettings = Loadable(lazy(() => import('../pages/dashboard/AccountSettings')));

// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';
// config
import { HOST_API } from '../config';
// locale
import i18n from '../locales/i18n';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  // baseURL: '', // HOST_API,
  baseURL: HOST_API,
});

// console.log('env REACT_APP_USE_MOCK', process.env.REACT_APP_USE_MOCK);

export const mock = process.env.REACT_APP_USE_MOCK?new AxiosMockAdapter(axiosInstance, { delayResponse: 0 }):{};

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const params = { ...(config.params && config.params || {})    };
    params.lang = i18n.language;

    const newConfig = { ...config, params };

    return newConfig;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  });

axiosInstance.interceptors.response.use(
  (response) => response,
  // (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
  (error) => Promise.reject((error.response && error.response.statusText) || 'Something went wrong')
);

export default axiosInstance;

// config
import { HOST_API } from '../config';
// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const DOMAIN = ''; // 'http://127.0.0.1:8000';
const V2 = '/v2';
const V3 = '/v3';
const ROOTS_LETSMEET = `${DOMAIN}/api${V2}/letsmeet`;
const ROOTS_LETSMEET_V3 = `${DOMAIN}/api${V3}/letsmeet`;
const ROOTS_LETSMEET_AJAX = `${DOMAIN}${V2}/letsmeet/ajax`;
const ROOTS_LM_DASH = `${DOMAIN}/api${V2}/letsmeet/dashboard`;
const ROOTS_BRANCH = `${DOMAIN}/api/branch`;
const ROOTS_BRANCH_V2 = `${DOMAIN}/api${V2}/branch`;
const ROOTS_USER_URL = `${DOMAIN}/api${V2}/users`;
const ROOTS_USER_URL_V1 = `${DOMAIN}/api/users`;
const ROOT_EXPORT = `${HOST_API}${ROOTS_LM_DASH}`;
const ROOTS_MEMBER = `${DOMAIN}/api${V2}/member`;

// ----------------------------------------------------------------------

export const URL_AUTH = {
  validate: path(ROOTS_USER_URL, '/validate-session/'),
  login: path(ROOTS_USER_URL, '/login/new/'),
  login2: path(ROOTS_USER_URL, '/email/login/initiate/'),
  logout: path(ROOTS_USER_URL, '/logout/'),
  socialLogin: path(ROOTS_USER_URL_V1, '/social-login/'),
  verifyOTP: path(`${DOMAIN}/api/otp`, '/newverify/'),
  verifyOTPLogin: path(ROOTS_USER_URL, "/email/login/complete/"),
  resendOTP: path(`${DOMAIN}/api/otp`, '/resend/'),
  resetPassword: path(ROOTS_USER_URL, '/reset-password/'),
  setPassword: path(ROOTS_USER_URL, '/set-password/'),
  finishSignUp: path(ROOTS_USER_URL_V1, '/signup/'),
  signup: path(ROOTS_USER_URL, "/email/signup/"),
  finishNormalSignUp: path(ROOTS_USER_URL, "/email/signup/complete/"),
  finishVenueSignUp: path(ROOTS_USER_URL, "/email/venuesignup/complete/"),
};

export const URL_GLOBAL = {
  wifi: path(ROOTS_USER_URL, '/wifi/'),
};

export const URL_OVERVIEW = {
  overview: path(ROOTS_LM_DASH, '/home/'),
  checkIns: path(ROOTS_LM_DASH, '/checkins/'),
  checkout: path(ROOTS_MEMBER, '/check_out/'),
};

export const URL_CALENDAR = {
  // /letsmeet/ajax/get-calendar-event-details/757/
  spaces: path(ROOTS_LM_DASH, '/calendar/branch_listing/'), // spaces_listing'),
  create: path(ROOTS_LM_DASH, '/calendar/events/new'),
  delete: path(ROOTS_LM_DASH, '/calendar/events/delete'),
  update: path(ROOTS_LETSMEET, '/space_type/book/'),
  cancel: path(ROOTS_LETSMEET, '/space/booking/cancel/'),
  createblock: path(ROOTS_LETSMEET_AJAX, '/save_block_time/'),
  deleteblock: path(ROOTS_LETSMEET_AJAX, '/delete_block_time/'), // /ID
  updateblock: path(ROOTS_LETSMEET, '/space/update-blocked-slot/'),
  events: path(ROOTS_LETSMEET_AJAX, '/get-calendar-events/'),
};

export const URL_BOOKING = {
  details: path(ROOTS_LETSMEET_AJAX, '/get-calendar-event-details/'), // /ID
};

export const URL_SALES = {
  list: path(ROOTS_LM_DASH, '/sales_listing/'),
  chart: path(ROOTS_LM_DASH, '/sales_chart_listing/'),
  export: path(ROOT_EXPORT, '/sales_listing/export/'), // ?duration=day
  details: path(ROOTS_LETSMEET_AJAX, '/get-calendar-event-details/'),
};

export const URL_COWORK_SALES = {
  list: path(ROOTS_LM_DASH, '/checkins_sales_chart_listing/'),
};

export const URL_CUSTOMERS = {
  list: path(ROOTS_LM_DASH, '/customers_listing/'),
  export: path(ROOT_EXPORT, '/customers_listing/export/'), // ?duration=day&search_val=A
  checkIns: path(ROOTS_LM_DASH, '/checkins/'),
  reviews: path(ROOTS_LM_DASH, '/reviews/'),
  exportAllCheckIns: path(ROOTS_MEMBER, '/membercheckin/listing/export/'), // ?start_date=2023-09-06&end_date=2023-10-05
};

export const URL_PLACES = {
  branches: path(ROOTS_LM_DASH, '/branch_listing/'),
  spaces: path(ROOTS_LM_DASH, '/space_listing/'),
  cities: path(ROOTS_BRANCH, '/locations/listing/'),
  countries: path(ROOTS_BRANCH, '/countries/listing/'),
  facilityOptions: path(ROOTS_BRANCH, '/facilities/listing/'),
  coworkInfoOptions: path(ROOTS_BRANCH, '/onboarding/information/'),
  branchdetails: path(ROOTS_BRANCH_V2, '/detail/'), // ?branch_id=110 // V1 API for customer portal - V2 API for dashboard
  createBranch: path(ROOTS_LETSMEET, '/branch/'),
  coworkInfo: path(ROOTS_LETSMEET, '/branch/sharedworkspace/'), // ?branch_id=110
  drink: path(ROOTS_LETSMEET, '/branch/recommended_bite/'), // ?branch_id=110
  media: path(ROOTS_LETSMEET, '/media-upload/'), // ?branch_id=110 &space_id=75
  policy: path(ROOTS_LETSMEET, '/branch/policies/'),
  facility: path(ROOTS_LETSMEET, '/branch/facility/'), // for both save
  deleteFacility: path(ROOTS_LETSMEET, '/branch/facility/delete/'), // facility/delete/<branch_id>/<facility_id>/
  notification: path(ROOTS_LETSMEET, '/branch/set-notification-settings/'),
  timings: path(ROOTS_LETSMEET, '/branch/timings/'),
  setupOptions: path(DOMAIN, '/api/letsmeet/setup/listing/'),
  amenityOptions: path(ROOTS_BRANCH, '/amenities/listing/'),
  calendarList: path(ROOTS_LETSMEET, '/googlecalendarlist/'),
  linkCalendar: path(ROOTS_LETSMEET, '/add_google_space/'),
  createSpace: path(ROOTS_LETSMEET, '/space/add-update/'),
  disableSpace: path(ROOTS_LM_DASH, '/space_disable/'), // <space_id>
  spacedetails: path(ROOTS_LETSMEET, '/space/detail/'), // ?branch_id=110&space_id=51&no_currency_change=true
  ddrPackage: path(ROOTS_LETSMEET, '/space/ddr-packages/'),
  ddrPackageDelete: path(ROOTS_LETSMEET, '/space/ddr-packages/delete/'),
  dateRangeBooking: path(ROOTS_LETSMEET_V3, "/space/daterangebooking/listing/"),
  availableSlots: path(ROOTS_LETSMEET, "/meeting-rooms/available_slots/"),
  openEstimation: path(DOMAIN, "/api/letsmeet/meeting-rooms/get-open-estimation/"),
  bookRoom: path(ROOTS_LETSMEET_V3, "/space/vm/booking/"),
};

export const URL_SETTINGS = {
  profile: path(ROOTS_USER_URL, '/get-profile/'),
  setProfile: path(ROOTS_USER_URL, '/set-profile/'),
  updateProfileImg: path(ROOTS_USER_URL, '/set-profile-image/'),
  updatePwd: path(ROOTS_USER_URL, '/change-password/'),
};

// @mui
import { styled } from '@mui/material/styles';
// components
import Page from '../../components/Page';
// sections
import {
  SignUpHero,
} from '../../sections/auth/signup';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(() => ({
  height: '100%',
  '--dave-purple': `140, 81, 245`, // #8C51F5
  '--dave-yellow': '247, 247, 94', // #F7F75E
  '--dave-dark': '11, 5, 14', // #0B050E
  '--dave-blue': '219, 238, 255', // #DBEEFF
  '--dave-green': '241, 255, 173', // #F1FFAD

  '--dave-yellow2': '255, 252, 178', // #FFFCB2
  '--dave-green2': '242, 255, 174', // #F2FFAE
  // background: 'rgba(var(--dave-dark), 1)',
  'background-image': `radial-gradient(
      circle at 10% 0%,
      rgba(var(--dave-green), 0.8) 0%,
      transparent 62%
    ),
    radial-gradient(
      circle at 72% 58%,
      rgba(var(--dave-blue), 1) 0%,
      transparent 49%
    ),
    radial-gradient(
      circle at 85% 85%,
      rgba(var(--dave-yellow), 0.9) 0%,
      transparent 15%
    )`,
}));

// ----------------------------------------------------------------------

export default function HomePage() {
  return (
    <Page title="Letsmeet | Become a host">
      <RootStyle>
        <SignUpHero defaultScreen="login" />
      </RootStyle>
    </Page>
  );
}

import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import jwtDecode from 'jwt-decode';
import PhoneInput,{ isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Box, Alert, IconButton, InputAdornment, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
// assets
import { FacebookIcon } from '../../../assets';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import useLocales from '../../../hooks/useLocales';
import useResponsive from '../../../hooks/useResponsive';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
//
import PhoneInputWrapper from './PhoneInputWrapper';

// ----------------------------------------------------------------------
const GENERAL_ERROR = 'Could not process this request.';
const CornerCutShapeStyle = styled(Box)(()=>({
  '-webkit-clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 17% 100%, 0% 75%)',
  'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 17% 100%, 0% 75%)',
}));
// ----------------------------------------------------------------------

LoginForm.propTypes = {
  changeScreen: PropTypes.func,
};

// ----------------------------------------------------------------------

Yup.addMethod(Yup.string, "validatePhone", function (errorMessage) {
  // console.log('this',this);
  return this.test(`validate-phone-number`, errorMessage,  (value) => {
    const { path, createError } = this;
    return (
      isValidPhoneNumber(value) ||
      createError({ path, message: errorMessage })
    );
  });
});

// ----------------------------------------------------------------------

export default function LoginForm({ changeScreen }) {
  const { login2 } = useAuth();
  const navigate = useNavigate();
  const { translate, currentLang } = useLocales();
  const isDesktop = useResponsive('up', 'sm');

  const isMountedRef = useIsMountedRef();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(translate('form.error-validEmail')).required(translate('form.error-email')),
  });

  const defaultValues = {
    email: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise.execute('6Ldr-KogAAAAAB1Qt2leeQriuqfmAYAwxESSEQvr', {action: 'login'}).then(async (token) => {
        console.log('LoginForm onSubmit', JSON.stringify(data,null,2));
        // const postData = { ...(continueWithEmail && { email: data.email} || {mobile: data.mobile}), password: data.password};
        try {
          const responseData = await login2(data.email, token);

          if (responseData.error?.message || 0) {
            setError('afterSubmit', responseData.error);
          } else if (responseData?.status && responseData.signup_uuid) {
                changeScreen('OTP');
                
          } else {
            console.log("error");
            setError('afterSubmit', { message: GENERAL_ERROR });
          }
          
        } catch (error) {
          // console.error(error);
          reset();
          if (isMountedRef.current) {
            setError('afterSubmit', { message: GENERAL_ERROR });
          }
        }

      });
    })
  };

  return (
    <div>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
          <RHFTextField
            name="email"
            InputLabelProps={{ shrink: true }}
            label={translate('form.email')}
            placeholder={translate('form.placeholder-email')}
            sx={{ mb: 2 }}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 4 }}>
          <Typography sx={{ color: '#767676', display: 'flex' }}>
            Don't have an account?
            <Link component={RouterLink} variant="body1" to={PATH_AUTH.signup} sx={{ fontWeight: 'fontWeightMedium', pl: 1 }}>
              {/* {translate('login.forgotPassword')} */}
              Sign up
            </Link>
          </Typography>
          <CornerCutShapeStyle sx={{ mb: 2, position: 'relative'}}>
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {/* {translate('login.btn-continue')} */}
              Login
            </LoadingButton>
          </CornerCutShapeStyle>
        </Stack>
      </FormProvider>
    </div>
  );
}

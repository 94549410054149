import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/auth/Login2';
import Welcome from '../pages/Welcome';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../routes/paths';
// components
import LoadingScreen from '../components/LoadingScreen';
// utils
import { TRACK_PAGE_VIEWED } from '../utils/trackAnalytics';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized, user } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  // console.log('isAuthenticated',isAuthenticated);
  // console.log('isInitialized',isInitialized);
  // console.log('pathname',pathname);
  // console.log('requestedLocation',requestedLocation);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    if(isAuthenticated && user?.isNew)
      return <Navigate to={PATH_AUTH.welcome} />
    return <Navigate to={requestedLocation} />;
  }

  TRACK_PAGE_VIEWED();

  return <>{children}</>;
}

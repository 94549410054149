import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

const PhoneInputWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  '& .PhoneInputCountry':{ // country select
    position: 'absolute',
    zIndex: 1,
    marginLeft: '16px',
    marginTop: '20px',
  },
  '& .PhoneInputInput': { // mobile input field
    border: '1px solid',
    borderRadius: '8px',
    color: theme.palette.text.primary,
    borderColor: 'rgba(145, 158, 171, 0.32)',
    background: theme.palette.background.default,
    padding: '16.5px 14px',
    paddingLeft: '70px',
    fontFamily: 'BrandonText',
    fontSize: '16px',
    fontWeight: '400',
  },
  '&.gradientbg .PhoneInputInput':{
    background: 'transparent',
  },
  
  '& .PhoneInputInput:-webkit-autofill':{
    WebkitBoxShadow: "0 0 0 100px #266798 inset",
    WebkitTextFillColor: "#fff",
    caretColor: "#fff"
  },
  '& span.mobile-label': { // mobile field label
    position: 'absolute',
    fontSize: '12px',
    marginLeft: '10px',
    marginTop: '-10px',
    // background: theme.palette.background.default,
    background: 'transparent',
    padding: '0 5px',
    color: '#919EAB',
    top: 0,
  },
  '&.gradientbg span.mobile-label':{
    // background: '#0B050E',
    // backgroundImage: 'radial-gradient(circle at 0 0, rgba(140, 81, 245, 0.4) 100%, transparent 80%)',
    background: 'white',
    backgroundImage: 'radial-gradient(circle at 0 0, rgba(241, 255, 173, 0.5) 100%, transparent 80%)',
  },

  '& .PhoneInputInput::-webkit-input-placeholder': {
    color: theme.palette.text.disabled, // '#919EAB',
  },
  '& .PhoneInputInput:hover': {
    borderColor: theme.palette.text.primary,
  },

  // FOCUS style
  '& .PhoneInputInput:focus': {
    outline: 'solid',
    borderColor: 'transparent',
    outlineColor: theme.palette.primary.main,
  },
  '& .PhoneInputInput:focus-visible': {
    outline: 'solid',
    borderColor: 'transparent',
    outlineColor: theme.palette.primary.main,
  },

  // ERROR SCENARIOS
  '&.input-error .PhoneInputInput': {
    borderColor: theme.palette.error.main,
  },
  '&.input-error span.mobile-label': {
    color: theme.palette.error.main,
  },
  '&.input-error .PhoneInputInput:focus': {
    outlineColor: theme.palette.error.main,
  },
  '&.input-error .PhoneInputInput:focus-visible': {
    outlineColor: theme.palette.error.main,
  },

}));

export default PhoneInputWrapper;

import { createSlice } from '@reduxjs/toolkit';
import { startOfMonth, startOfYear, endOfYear, setYear, addMonths, endOfMonth } from 'date-fns';
// utils
import axios from '../../utils/axios';
import { fDisplay, fTimestampNow } from '../../utils/formatTime';
import { URL_SALES } from '../../utils/restApiUrls';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const TABLE_FETCH_ERROR = 'sales.error.fetchBookSales';
const MORE_DETAILS_FETCH_ERROR = 'sales.error.fetchMoreBookSalesDetails';
const EXPORT_TABLE_ERROR = 'sales.error.exportBookSales';
const CHART_FETCH_ERROR = 'sales.error.fetchBookChart';
// ----------------------------------------------------------------------
// const DUARATION_LABELS = {
//   'year': 'this year',
//   'month': 'this month',
//   'day': 'today',
//   'all': 'till date',
// };
const DEFAULT_DURATION = 'month';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: true,
  isChartUpdating: false,
  isTableUpdating: false,
  isMoreDetailsLoading: false,
  updateTimeStamp: fTimestampNow(),
  error: null,
  alertError: null,
  alertSuccess: null,
  branchRevenues: null,
  branchSelected: null,
  salesList: null,
  checkInsSalesData: null,
  moreDetails: null,
  duration: DEFAULT_DURATION,
  dateRange: null,
  start: null,
  end: null,
  page: 0,
  count: 0,
  order: 'asc',
  searchQuery: '',
  rowsPerPage: 10,
  isOpenModal: false,
  moreDetailsId: null,
};

const slice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START CHART UPDATING
    startChartUpdating(state) {
      state.isChartUpdating = true;
    },

    // START TABLE UPDATING
    startTableUpdating(state) {
      state.isTableUpdating = true;
    },

    // START MORE DETAILS LOADING
    startMoreDetailsLoading(state) {
      state.isMoreDetailsLoading = true;
    },

    // START MORE DETAILS LOADING
    setUpdateTable(state) {
      const newTimeStamp = fTimestampNow();
      console.log('update... setUpdateTable', newTimeStamp);
      state.updateTimeStamp = newTimeStamp;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Display Alert Success
    setAlertSuccess(state, action) {
      const message = action.payload;
      state.alertSuccess = message;
    },

    // Display Alert Error
    setAlertError(state, action) {
      const message = action.payload;
      state.alertError = message;
    },

    // Reset Alert Success
    resetAlertSuccess(state) {
      state.alertSuccess = null;
    },

    // Reset Alert Error
    resetAlertError(state) {
      state.alertError = null;
    },

    // GET SALES TABLE DETAILS
    getSalesListSuccess(state, action) {
      state.isLoading = false;
      state.isTableUpdating = false;
      // eslint-disable-next-line camelcase
      const { results, total_count, current_page } = action.payload;
      if (results.length) {
        // eslint-disable-next-line camelcase
        state.count = total_count;
        // eslint-disable-next-line camelcase
        state.page = current_page - 1;
        state.salesList = results;
      } else {
        state.count = 0;
        state.salesList = [];
      }
    },

    // GET SALES TABLE DETAILS
    getSalesListError(state) {
      state.isLoading = false;
      state.isTableUpdating = false;
      // state.salesList = [];
    },

    // GET MORE TABLE ROW DETAILS
    getMoreDetailsSuccess(state, action) {
      state.moreDetails = action.payload.context ? action.payload.context : action.payload;
    },

    // GET CHART DATA
    getChartDataSuccess(state, action) {
      state.isLoading = false;
      state.isChartUpdating = false;
      const { context } = action.payload;
      const sortedData =
        context.length > 1 ? context.filter((x) => x.branch_revenue).sort(numericSort) : context.slice();
      state.branchRevenues = sortedData;
      state.branchSelected = (sortedData.length && sortedData[0]) || {}; //
    },

    // GET CHART DATA
    updateDuration(state, action) {
      const newDuration = action.payload;
      state.duration = newDuration;
      state.page = 0;
      if (newDuration !== 'custom') {
        state.dateRange = null;
      }
      //   state.updateTimeStamp = fTimestampNow();
    },

    // GET CHART DATA
    updateCustomDuration(state, action) {
      state.dateRange = action.payload;
      state.page = 0;
      console.log('updateCustomDuration page', state.dateRange);
      // state.updateTimeStamp = fTimestampNow();
    },

    // GET CHART DATA
    updatePage(state, action) {
      state.page = action.payload;
      // state.updateTimeStamp = fTimestampNow();
    },

    // GET CHART DATA
    handleSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },

    // GET CHART DATA
    handleBranchRevenueClick(state, action) {
      const property = action.payload;
      state.branchSelected = state.branchRevenues[property];
    },

    // OPEN MORE DETAILS MODAL POPUP
    handleOpenModal(state, action) {
      state.isOpenModal = true;
      state.moreDetailsId = action.payload;
    },

    // CLOSE MODAL
    handleCloseModal(state) {
      state.isOpenModal = false;
      state.moreDetailsId = null;
      state.moreDetails = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  updateDuration,
  updateCustomDuration,
  updatePage,
  handleSearchQuery,
  handleBranchRevenueClick,
  handleCloseModal,
  handleOpenModal,
  setUpdateTable,
  resetAlertSuccess,
  resetAlertError,
} = slice.actions;

// ----------------------------------------------------------------------

export function getSalesList(durationLabel, p, search, dateRange) {
  console.log('getSalesList', durationLabel, p, search, dateRange);
  return async () => {
    dispatch(slice.actions.startTableUpdating());
    try {
      const response = await axios.get(URL_SALES.list, {
        params: {
          p: p + 1,
          ...((search && { search }) || {}),
          ...getRange(durationLabel, dateRange), // to get range for day, month, year, all
          ...((dateRange && dateRange.start && { start_date: dateRange.start_date, end_date: dateRange.end_date }) ||
            {}),
        },
      });
      if (response.data.status) {
        const getData = response.data;
        dispatch(slice.actions.getSalesListSuccess(getData));
      } else {
        dispatch(slice.actions.getSalesListError());
        dispatch(slice.actions.hasError(response.data.error?.title || TABLE_FETCH_ERROR));
        dispatch(slice.actions.setAlertError(response.data.error?.title || TABLE_FETCH_ERROR));
      }
    } catch (error) {
      dispatch(slice.actions.getSalesListError());
      dispatch(slice.actions.hasError(TABLE_FETCH_ERROR));
      dispatch(slice.actions.setAlertError(TABLE_FETCH_ERROR));
    }
  };
}

// ----------------------------------------------------------------------

export function getMoreDetails(id) {
  return async () => {
    dispatch(slice.actions.startMoreDetailsLoading());
    try {
      const response = await axios.get(`${URL_SALES.details}${id}/`);
      if (response.data.status) {
        const getData = response.data;
        dispatch(slice.actions.getMoreDetailsSuccess(getData));
      } else {
        dispatch(slice.actions.setAlertError(response.data.error?.title || MORE_DETAILS_FETCH_ERROR));
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(MORE_DETAILS_FETCH_ERROR));
    }
  };
}

// ----------------------------------------------------------------------

export function handleExport(durationLabel, search, dateRange) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(URL_SALES.export, {
        responseType: 'blob',
        params: {
          ...((search && { search }) || {}),
          ...getRange(durationLabel, dateRange), // to get range for day, month, year, all
          ...((dateRange && dateRange.start && { start_date: dateRange.start_date, end_date: dateRange.end_date }) ||
            {}),
        },
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Sales.xls'); // or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      dispatch(slice.actions.setAlertError(EXPORT_TABLE_ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function getChartData(durationLabel, dateRange) {
  return async () => {
    dispatch(slice.actions.startChartUpdating());
    try {
      const response = await axios.get(URL_SALES.chart, {
        params: {
          ...getRange(durationLabel, dateRange), // to get range for day, month, year, all
          ...((dateRange && dateRange.start && { start_date: dateRange.start_date, end_date: dateRange.end_date }) ||
            {}),
        },
      });
      if (response.data.status) {
        dispatch(slice.actions.getChartDataSuccess(response.data));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || CHART_FETCH_ERROR)
        );
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(CHART_FETCH_ERROR));
    }
  };
}

// ----------------------------------------------------------------------
//              helper
// ----------------------------------------------------------------------

function numericSort(a, b) {
  return b.branch_revenue - a.branch_revenue;
}

function getRange(durationLabel, dateRange) {
  const NOW = new Date();
  const DATE_FORMAT = 'yyyy-MM-dd';
  const duration = dateRange && dateRange.start ? 'custom' : durationLabel;
  const range = {
    start_date: null,
    end_date: fDisplay(NOW, DATE_FORMAT),
  };
  const lastMonthDate = addMonths(new Date(), -1);
  switch (duration) {
    case 'monthLast':
      range.start_date = startOfMonth(lastMonthDate);
      range.end_date = fDisplay(endOfMonth(lastMonthDate), DATE_FORMAT);
      break;
    case 'month':
      range.start_date = startOfMonth(NOW);
      range.end_date = fDisplay(endOfMonth(NOW), DATE_FORMAT);
      break;
    case 'year':
      range.start_date = startOfYear(NOW);
      range.end_date = fDisplay(endOfYear(NOW), DATE_FORMAT);
      break;
    case 'day':
      range.start_date = NOW;
      break;
    case 'all':
      range.start_date = setYear(startOfYear(NOW), 1970);
      range.end_date = fDisplay(addMonths(NOW, 3), DATE_FORMAT);
      break;
    case 'custom':
    default:
      break;
  }
  try {
    range.start_date = fDisplay(range.start_date, DATE_FORMAT);
  } catch (e) {
    //
  }

  return {
    ...((range.start_date && range) || {}),
  };
}

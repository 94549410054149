import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { URL_OVERVIEW } from '../../utils/restApiUrls';
import { dispatch } from '../store';

const KPIS_FETCH_ERROR = 'overview.error.fetchKPIs';
const CHECKINS_FETCH_ERROR = 'overview.error.fetchCheckIns';
const CHECKOUT_SUCCESS = 'overview.success.checkout';
const CHECKOUT_ERROR = 'overview.error.checkout';

const initialState = {
  isKPIsLoading: true,
  isCheckInsLoading: true,
  updatingUuids: [],
  isCheckinsUpdated: false,
  error: null,
  alertError: null,
  alertSuccess: null,
  kpis: null,
  checkIns: null,
  page: 0,
  count: 0,
  rowsPerPage: 100,
};

const slice = createSlice({
  name: 'overview',
  initialState,
  reducers: {
    startKPIsLoading(state) {
      state.isKPIsLoading = true;
    },

    startCheckInsLoading(state) {
      state.isCheckInsLoading = true;
    },

    startCheckinUpdating(state) {
      state.isCheckinsUpdated = true; // needs refresh
    },

    startCheckingOut(state, action) {
      const uuid = action.payload;
      state.updatingUuids.push(uuid);
    },

    hasError(state, action) {
      state.isKPIsLoading = false;
      state.isCheckInsLoading = false;
      state.error = action.payload;
    },

    setAlertSuccess(state, action) {
      const message = action.payload;
      state.alertSuccess = message;
    },

    setAlertError(state, action) {
      const message = action.payload;
      state.alertError = message;
    },

    resetAlertSuccess(state) {
      state.alertSuccess = null;
    },

    resetAlertError(state) {
      state.alertError = null;
    },

    kpisLoadingDone(state) {
      state.isKPIsLoading = false;
    },

    checkInsLoadingDone(state) {
      state.isCheckInsLoading = false;
    },

    checkinUpdatingDone(state) {
      state.isCheckinsUpdated = false; // refresh done
    },

    getKpisSuccess(state, action) {
      state.isKPIsLoading = false;
      const { context } = action.payload;
      state.kpis = context;
    },

    getCheckInsSuccess(state, action) {
      state.isCheckInsLoading = false;
      state.isCheckinsUpdated = false; // refresh done
      // eslint-disable-next-line camelcase
      const { results, total_count, current_page } = action.payload;
      if (results.length) {
        // eslint-disable-next-line camelcase
        state.count = total_count;
        // eslint-disable-next-line camelcase
        state.page = current_page - 1;
        state.checkIns = results;
      } else {
        state.count = 0;
        state.checkIns = [];
      }
    },

    checkoutLoadingDone(state, action) {
      const uuid = action.payload;
      console.log('uuid', uuid);

      const newUpdatingUuids = state.updatingUuids.filter((item) => item !== uuid);
      state.updatingUuids = newUpdatingUuids;
    },

    updatePage(state, action) {
      state.page = action.payload;
    },
  },
});

export default slice.reducer;

export const { updatePage, resetAlertSuccess, resetAlertError } = slice.actions;

export function getKPIs() {
  return async () => {
    dispatch(slice.actions.startKPIsLoading());

    try {
      const response = await axios.get(URL_OVERVIEW.overview);
      if (response.data.status) {
        const { data } = response;
        dispatch(slice.actions.getKpisSuccess(data));
      } else {
        const message = response.data.error?.title !== "Error" && response.data.error?.title ||
                        response.data.error?.message || KPIS_FETCH_ERROR;
        dispatch(slice.actions.kpisLoadingDone());
        dispatch(slice.actions.hasError(message));
        dispatch(slice.actions.setAlertError(message));
      }
    } catch (error) {
      dispatch(slice.actions.kpisLoadingDone());
      dispatch(slice.actions.hasError(KPIS_FETCH_ERROR));
      dispatch(slice.actions.setAlertError(KPIS_FETCH_ERROR));
    }
  };
}

export function getCheckIns(page) {
  return async () => {
    dispatch(slice.actions.startCheckInsLoading());
    try {
      const response = await axios.get(URL_OVERVIEW.checkIns, {
        params: {
          page: page + 1,
        },
      });
      if (response.data.status) {
        const { data } = response;
        dispatch(slice.actions.getCheckInsSuccess(data));
      } else {
        dispatch(slice.actions.checkInsLoadingDone());
        dispatch(slice.actions.hasError(response.data.error?.title || CHECKINS_FETCH_ERROR));
        dispatch(slice.actions.setAlertError(response.data.error?.title || CHECKINS_FETCH_ERROR));
      }
    } catch (error) {
      dispatch(slice.actions.checkInsLoadingDone());
      dispatch(slice.actions.hasError(CHECKINS_FETCH_ERROR));
      dispatch(slice.actions.setAlertError(CHECKINS_FETCH_ERROR));
    }
  };
}

export function refreshCheckIns() {
  return async () => {
    try {
      const response = await axios.get(URL_OVERVIEW.checkIns, {
        params: {
          page: 1,
        },
      });
      if (response.data.status) {
        const { data } = response;
        dispatch(slice.actions.getCheckInsSuccess(data));
      } else {
        dispatch(slice.actions.setAlertError(response.data.error?.title || CHECKINS_FETCH_ERROR));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(CHECKINS_FETCH_ERROR));
      dispatch(slice.actions.setAlertError(CHECKINS_FETCH_ERROR));
    }
  };
}

export function checkout(uuid) {
  dispatch(slice.actions.startCheckingOut(uuid)); // added uuid to list
  return async () => {
    try {
      const response = await axios.post(URL_OVERVIEW.checkout, {
        uuid,
      });
      if (response.data.status) {
        dispatch(slice.actions.checkoutLoadingDone(uuid)); // removing uuid from list
        // dispatch(slice.actions.startCheckingOut(uuid));
        dispatch(slice.actions.startCheckinUpdating(uuid)); // trigger refresh list
        dispatch(slice.actions.setAlertSuccess(CHECKOUT_SUCCESS));
      } else {
        dispatch(slice.actions.checkoutLoadingDone(uuid));
        dispatch(
          slice.actions.setAlertError(response.data.error?.title || response.data.error?.message || CHECKOUT_ERROR)
        );
      }
    } catch (error) {
      dispatch(slice.actions.checkoutLoadingDone(uuid));
      dispatch(slice.actions.setAlertError(CHECKOUT_ERROR));
    }
  };
}

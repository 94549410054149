import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number, showDecimals) {
  return numeral(number).format(Number.isInteger(number) && !showDecimals ? '0,0' : '0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function fDecimal(number) {
  return numeral(number).format('0.0');
}
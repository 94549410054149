import PropTypes from 'prop-types';
import { useState } from 'react';
import * as Yup from 'yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PhoneInput,{ isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
// redux
import { useDispatch } from '../../../redux/store';
import { logoutResetReduxStates } from '../../../redux/slices/settings';
// components
import { FormProvider, RHFTextField, RHFSelect } from '../../../components/hook-form';
// hooks
import useLocales from '../../../hooks/useLocales';
//
import PhoneInputWrapper from './PhoneInputWrapper';

// ----------------------------------------------------------------------
const GENERAL_ERROR = 'Could not process this request.';
const CornerCutShapeStyle = styled(Box)(()=>({
  '-webkit-clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 17% 100%, 0% 75%)',
  'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 17% 100%, 0% 75%)',
}));
/*
Yup.addMethod(Yup.string, "validatePhone",  (errorMessage) => {
  const testMethod = this.test;
  return testMethod(`validate-phone-number`, errorMessage,  (value) => {
    const { path, createError } = this;
    return (
      isValidPhoneNumber(value) ||
      createError({ path, message: errorMessage })
    );
  });
}); */
Yup.addMethod(Yup.string, "validatePhone", function (errorMessage) {
  return this.test(`validate-phone-number`, errorMessage,  (value) => {
    const { path, createError } = this;
    return (
      isValidPhoneNumber(value) ||
      createError({ path, message: errorMessage })
    );
  });
});

// ----------------------------------------------------------------------

FinishSignUp.propTypes = {
  changeScreen: PropTypes.func,
  countries: PropTypes.array,
};

export default function FinishSignUp({ changeScreen, countries }) {
  const { signUp, user } = useAuth();
  const [error, setError] = useState(false);
  const [info, setInfo] = useState(false);
  const { Translate, translate: t } = useLocales();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const FinishSignUpSchema = Yup.object().shape({
    name: Yup.string().required(t('form.error-name')).min(3, t('login.error-fullNameMin')),
    mobile: Yup.string().required(t('form.error-mobile'))
      .validatePhone(t('form.error-validMobile')),
    email: Yup.string().required(t('form.error-email')).email(t('form.error-validEmail')),
    country: Yup.number().required(t('form.error-country')),
  });

  const defaultValues = {
    name: user?.name || '',
    // name: user.firstName ? `${user.firstName}${user.lastName && ` ${user.firstName}`||''}` : '',
    mobile: user?.mobile || '',
    email: user?.email || '',
    country: 1,
  };

  const methods = useForm({
    resolver: yupResolver(FinishSignUpSchema),
    defaultValues,
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    console.log('FinishSignUp onSubmit');
    console.log('form data', data);

    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise.execute('6Ldr-KogAAAAAB1Qt2leeQriuqfmAYAwxESSEQvr', {action: 'login'}).then(async (token) => {
        const postData = { ...data, token }; // 'country_id': data.country, 'signup_uuid': user.signupUuid, password: user.password, 
        try {
          const responseData = await signUp(postData);

          if (responseData.error?.message || 0) {
            setError('afterSubmit', responseData.error);
          } else if (responseData?.status && responseData.signup_uuid) {
                changeScreen('OTP');
                
          } else {
            console.log("error");
            setError('afterSubmit', { message: GENERAL_ERROR });
          }
          
        } catch (error) {
          // console.error(error);
          reset();
          // if (isMountedRef.current) {
            setError('afterSubmit', { message: GENERAL_ERROR });
          // }
        }
      });
    })
  };

  return (
    <div>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {!!info && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {info}
          </Alert>
        )}
        <Stack spacing={3}>
          <RHFTextField name="name" label={t('login.form-fullName')} InputLabelProps={{ shrink: true }} />
          <RHFTextField name="email" label={t('form.email')} {...(user?.email && {readOnly:true} || {})} InputLabelProps={{ shrink: true }} />
          <Controller
            name="mobile"
            control={control}
            {...(user?.mobile && {readOnly:true} || {})}
            render={({ field: { onChange }, fieldState: { error } }) => (
              <PhoneInputWrapper
                label="Mobile"
                className={error && 'input-error gradientbg' || 'gradientbg'}
              >
                <span className="mobile-label">{t('form.mobile')}</span>
                <PhoneInput
                  id="mobile"
                  defaultCountry="AE"
                  onChange={onChange}
                />
                {
                  error && error.message && (
                    <Typography variant="caption" color="error.main" sx={{ mt: 1, ml: 2}} paragraph>
                      {error.message}
                    </Typography>
                  ) || ''
                }
              </PhoneInputWrapper>
            )}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 4 }}>
          <Typography sx={{ color: '#767676', display: 'flex' }}>
            Already have an account?
            <Link component={RouterLink} variant="body1" to={PATH_AUTH.login} sx={{ fontWeight: 'fontWeightMedium', pl: 1 }}>
              {/* {translate('login.forgotPassword')} */}
              Login
            </Link>
          </Typography>
          <CornerCutShapeStyle sx={{ mb: 2, position: 'relative'}}>
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {/* {translate('login.btn-finishSignUp')} */}
              Sign up
            </LoadingButton>
          </CornerCutShapeStyle>
        </Stack>
        {!!error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error.message}
          </Alert>
        )}
      </FormProvider>
    </div>
  );
}

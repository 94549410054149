import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { URL_CALENDAR } from '../../utils/restApiUrls';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const SPACES_FETCH_ERROR = 'calendar.error.fetchSpaces';
const NO_EVENTS_ERROR = 'calendar.error.fetchSpecificEvent';
const GENERAL_EVENTS_ERROR = 'calendar.error.fetchEvents';
const BT_CREATE_SUCCESS = 'calendar.success.blockTime'; // BT: Block TIme
const BT_CREATE_ERROR = 'calendar.error.blockTime'; // BT: Block TIme
const BT_UPDATE_SUCCESS = 'calendar.success.updateBlockTime'; // BT: Block TIme edit success
const BT_UPDATE_ERROR = 'calendar.error.updateBlockTime'; // BT: Block TIme edit error
const B_UPDATE_SUCCESS = 'calendar.success.updateBooking'; // Booking Update Success
const B_UPDATE_ERROR = 'calendar.error.updateBooking'; // Booking Update Error
const B_DELETE_SUCCESS = 'calendar.success.deleteBooking'; // Booking Delete Success
const B_DELETE_ERROR = 'calendar.error.deleteBooking'; // Booking Delete Error
const B_CANCEL_SUCCESS = 'calendar.success.cancelBooking'; // Booking Cancel Success
const B_CANCEL_ERROR = 'calendar.error.cancelBooking'; // Booking Cancel Error
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  alertError: null,
  alertSuccess: null,
  events: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
  isLoadingSpace: false,
  spaces: [],
  selectedBranch: null,
  selectedBranchId: null,
  selectedSpaceId: null,
  isEventUpdated: false,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },

    // START REFRESHING EVENTS
    startRefreshing(state) {
      state.isEventUpdated = true;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // HAS ERROR
    setEmptyEventError(state, action) {
      if(state.selectedBranch && state.selectedSpaceId){
        const messageText = action.payload;
        const branchName = state.selectedBranch.name;
        const spaceObj =  state.selectedBranch.children.find(x=>x.id===state.selectedSpaceId);
        const spaceName = spaceObj.name;
        state.error = messageText.replace('branchname',branchName).replace('spacename', spaceName);
      }
    },

    // Display Alert Success
    setAlertSuccess(state, action) {
      const message = action.payload;
      state.alertSuccess = message;
    },

    // Display Alert Error
    setAlertError(state, action) {
      const message = action.payload;
      state.alertError = message;
    },

    // Reset Alert Success
    resetAlertSuccess(state) {
      state.alertSuccess = null;
    },

    // Reset Alert Error
    resetAlertError(state) {
      state.alertError = null;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.isEventUpdated = false;
      const _events = (action.payload).map(x=>{
        /*
        '#00AB55', // theme.palette.primary.main,
        '#1890FF', // theme.palette.info.main,
        '#54D62C', // theme.palette.success.main,
        '#FFC107', // theme.palette.warning.main,
        '#FF4842', // theme.palette.error.main
        '#04297A', // theme.palette.info.darker
        '#7A0C2E', // theme.palette.error.darker
        */
        let textColorHex = '#1890FF'; // Credit - blue
        textColorHex = !x.credit_used ? '#00AB55' : textColorHex; // Paid - green
        textColorHex = x.internal ? '#FF4842' : textColorHex; // Blocked - red
        textColorHex = x.className === "theme-meeting-pumpkin" || x.internal === false ? '#FFC107' : textColorHex; // Google - yellow
        /*
         * className
         * MEET / OFFICE / CREATIVE - theme-meeting-purple
         * Letswork Block - theme-meeting-block
         * Google Calender Block - theme-meeting-pumpkin
         */
        // let textColorHex = '#1890FF'; // Credit - blue
        // textColorHex = !x.credit_used ? '#00AB55' : textColorHex; // Paid - green
        // textColorHex =  ? '#FF4842' : textColorHex; // Blocked - red
        const title = x.internal && x.block_reason ? `${x.title} (${x.block_reason})` : x.title;


        return {
          ...x,
          // internal: x.className === 'theme-meeting-block',
          textColor: textColorHex,
          title,
        }
      })
      state.events = _events;
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.events.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateEvent;
    },

    // CANCEL EVENT
    cancelEventSuccess(state, action) {
      const { eventId } = action.payload;
      console.log("Cancelled event", eventId)
      // const deleteEvent = state.events.filter((event) => event.id !== eventId);
      // state.events = deleteEvent;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId } = action.payload;
      const deleteEvent = state.events.filter((event) => event.id !== eventId);
      state.events = deleteEvent;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = +action.payload; // CONVERT TO INTEGER
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    },

    // START LOADING SPACES
    startLoadingSpace(state) {
      state.isLoadingSpace = true;
    },

    // GET BRANCH AND SPACE INFO
    getSpaceSuccess(state, action) {
      state.isLoadingSpace = false;
      const _spaces = action.payload;
      state.spaces = _spaces.slice();
      state.selectedBranch = _spaces[0];
      state.selectedBranchId = _spaces[0].id;
      state.selectedSpaceId = _spaces[0].children[0].id;
      // getEvents(state.selectedSpaceId);
    },

    // SELECT BRANCH
    selectBranch(state, action) {
      console.log('selectBranch.......',action.payload)
      const branchId = action.payload;
      const _spaces = state.spaces.slice();

      // const _selectedSpace =
      _spaces.find((_branch) =>{ console.log(_branch.id, branchId);
        if( _branch.id === branchId ){
          state.selectedBranchId = branchId;
          state.selectedBranch = _branch;
          // assuming all branches would have children
          state.selectedSpaceId = _branch.children[0].id;
          console.log("next fetch events...", getEvents, state.selectedSpaceId)
          // getEvents(state.selectedSpaceId);
        }
        return _branch.id === branchId;
      });
    },

    // SELECT SPACE
    selectSpace(state, action) {
      console.log('selectSpace.....',action.payload);
      const spaceId = action.payload;
      state.selectedSpaceId = spaceId;
      console.log("next fetch events...", getEvents, spaceId)
      // getEvents(spaceId);
      // state.isLoading = startLoading;
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent, selectBranch, selectSpace, resetAlertSuccess, resetAlertError } = slice.actions;

// ----------------------------------------------------------------------

export function getEvents(spaceId, start, end) {
  if(!spaceId) return {}; // Abort if no space information available
  console.log('getEvents......',spaceId, start, end);
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get(URL_CALENDAR.events, { params: { spaces: space.id.join(',') } });
      const response = await axios.get(URL_CALENDAR.events, { params: { spaces: spaceId.toString(), start, end } });
      if(response.data){
        dispatch(slice.actions.getEventsSuccess(response.data));
        if(!response.data.length) dispatch(slice.actions.setEmptyEventError(NO_EVENTS_ERROR));
      } else {
        dispatch(slice.actions.hasError(response.data.error?.message || GENERAL_EVENTS_ERROR));
        dispatch(slice.actions.setAlertError(response.data.error?.message || GENERAL_EVENTS_ERROR));
      }

    } catch (error) {
      dispatch(slice.actions.setEmptyEventError(NO_EVENTS_ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function refreshEvents(spaceId, start, end) {
  console.log('refreshEvents......',spaceId, start, end);
  return async () => {
    try {
      // const response = await axios.get(URL_CALENDAR.events, { params: { spaces: space.id.join(',') } });
      const response = await axios.get(URL_CALENDAR.events, { params: { spaces: spaceId.toString(), start, end } });
      if(response.data){
        dispatch(slice.actions.getEventsSuccess(response.data));
        if(!response.data.length) dispatch(slice.actions.setEmptyEventError(NO_EVENTS_ERROR));
      } else {
        dispatch(slice.actions.hasError(response.data.error?.message || GENERAL_EVENTS_ERROR));
        dispatch(slice.actions.setAlertError(response.data.error?.message || GENERAL_EVENTS_ERROR));
      }
    } catch (error) {
      dispatch(slice.actions.setEmptyEventError(NO_EVENTS_ERROR));
    }
  };
}

// ----------------------------------------------------------------------

export function createEvent(newEvent) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(URL_CALENDAR.createblock, newEvent);
      if(response.data.status){
        dispatch(slice.actions.startRefreshing());
        dispatch(slice.actions.setAlertSuccess(BT_CREATE_SUCCESS));
      } else {
        dispatch(slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || BT_CREATE_ERROR));
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(BT_CREATE_ERROR));
    }
  };
}

// ----------------------------------------------------------------------

export function editBlockEvent(newEvent) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(URL_CALENDAR.updateblock, newEvent);
      if(response.data.status){
        dispatch(slice.actions.startRefreshing());
        dispatch(slice.actions.setAlertSuccess(BT_UPDATE_SUCCESS));
      } else {
        dispatch(slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || BT_UPDATE_ERROR));
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(BT_UPDATE_ERROR));
    }
  };
}

// ----------------------------------------------------------------------

export function updateEvent(eventId, updateEvent, spaceType) {
  const apiUrl = URL_CALENDAR.update.replace('space_type', (spaceType || 'meeting-rooms'));
    console.log('updateEvent in redux', eventId, spaceType, apiUrl)
  return async () => {
    try {
      const response = await axios.post(apiUrl, {
        ...updateEvent,
      });
      if(response.data.status){
        dispatch(slice.actions.startRefreshing());
        dispatch(slice.actions.setAlertSuccess(B_UPDATE_SUCCESS));
      } else {
        dispatch(slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || B_UPDATE_ERROR));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(B_UPDATE_ERROR));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteEvent(eventId) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${URL_CALENDAR.deleteblock}${eventId}/`, { eventId });
      if(response.data.status){
        // dispatch(slice.actions.startRefreshing());
        dispatch(slice.actions.deleteEventSuccess({ eventId }));
        dispatch(slice.actions.setAlertSuccess(B_DELETE_SUCCESS));
      } else {
        dispatch(slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || B_DELETE_ERROR));
      }

    } catch (error) {
      dispatch(slice.actions.setAlertError(B_DELETE_ERROR));
    }
  };
}

// ----------------------------------------------------------------------

export function cancelEvent(eventId, reason) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(URL_CALENDAR.cancel, { booking_id: eventId, reason });
      if(response.data.status){
        dispatch(slice.actions.startRefreshing());
        dispatch(slice.actions.setAlertSuccess(response.data.message || B_CANCEL_SUCCESS));
      } else {
        dispatch(slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || B_CANCEL_ERROR));
      }

    } catch (error) {
      dispatch(slice.actions.setAlertError(B_CANCEL_ERROR));
    }
  };
}

// ----------------------------------------------------------------------

export function selectRange(start, end) {
  return async () => {
    dispatch(
      slice.actions.selectRange({
        start: start.getTime(),
        end: end.getTime(),
      })
    );
  };
}

// ----------------------------------------------------------------------

export function getSpaces() {
  return async () => {
    dispatch(slice.actions.startLoadingSpace());
    try {
      const response = await axios.get(URL_CALENDAR.spaces);
      // const response = await axios.get(`${URL_CALENDAR.spaces}123`); // To test error scenario
      if(response.data.status){
        dispatch(slice.actions.getSpaceSuccess(response.data.context));
        if(!response.data.context.length) dispatch(slice.actions.hasError(SPACES_FETCH_ERROR));
      } else {
        dispatch(slice.actions.hasError(response.data.error?.message || response.data.error?.title || SPACES_FETCH_ERROR));
        dispatch(slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || SPACES_FETCH_ERROR));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(SPACES_FETCH_ERROR));
    }
  };
}

import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { URL_PLACES } from '../../utils/restApiUrls';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const B_DETAILS_FETCH_ERROR = 'venueCreateEdit.error.fetchbranchDetails';
const B_DETAILS_LOAD_ERROR = 'venueCreateEdit.error.loadBranchDetails';
const F_DETAILS_FETCH_ERROR = 'venueCreateEdit.error.fetchFacilityDetails';
const C_DETAILS_FETCH_ERROR = 'venueCreateEdit.error.fetchCoworkOptionDetails';
const CON_DETAILS_FETCH_ERROR = 'venueCreateEdit.error.fetchCountryList';
const CITY_DETAILS_FETCH_ERROR = 'venueCreateEdit.error.fetchCityList';
const CREATE_EDIT_ERROR = 'venueCreateEdit.error.save';
const CREATE_SUCCESS = 'venueCreateEdit.success.createBranch';
const UPDATE_SUCCESS = 'venueCreateEdit.success.updateBranch';
const M_UPLOAD_SUCCESS = 'venueCreateEdit.success.uploadImage';
const M_UPLOAD_ERROR = 'venueCreateEdit.error.uploadImage';
const M_DELETE_SUCCESS = 'venueCreateEdit.success.deleteImage';
const M_DELETE_ERROR = 'venueCreateEdit.error.deleteImage';
const M_ISFRONT_SUCCESS = 'venueCreateEdit.success.setIsFrontImage';
const M_ISFRONT_ERROR = 'venueCreateEdit.error.setIsFrontImage';
const F_SET_SUCCESS = 'venueCreateEdit.success.addFacility';
const F_SET_ERROR = 'venueCreateEdit.error.addFacility';
const F_REMOVE__SUCCESS = 'venueCreateEdit.success.removeFacility';
const F_REMOVE__ERROR = 'venueCreateEdit.error.removeFacility';
const C_UPDATE_SUCCESS = 'venueCreateEdit.success.updateCoworkDetails';
const C_UPDATE_ERROR = 'venueCreateEdit.error.updateCoworkDetails';
const D_UPDATE_SUCCESS = 'venueCreateEdit.success.updateDrink';
const D_UPDATE_ERROR = 'venueCreateEdit.error.updateDrink';
const E_UPDATE__SUCCESS = 'venueCreateEdit.success.updateEmails';
const E_UPDATE__ERROR = 'venueCreateEdit.error.updateEmails';
const T_UPDATE__SUCCESS = 'venueCreateEdit.success.updateTimings';
const T_UPDATE__ERROR = 'venueCreateEdit.error.updateTimings';
const P_UPDATE__SUCCESS = 'venueCreateEdit.success.updatePolicies';
const P_UPDATE__ERROR = 'venueCreateEdit.error.updatePolicies';
// ----------------------------------------------------------------------
const DEFAULT_TIMING_LIST =  [{"is_closed":false,"start_time":"09:00:00","end_time":"23:00:00","weekday":"Monday"},{"is_closed":false,"start_time":"09:00:00","end_time":"23:00:00","weekday":"Tuesday"},{"is_closed":false,"start_time":"09:00:00","end_time":"23:00:00","weekday":"Wednesday"},{"is_closed":false,"start_time":"09:00:00","end_time":"23:00:00","weekday":"Thursday"},{"is_closed":true,"start_time":"09:00:00","end_time":"23:00:00","weekday":"Friday"},{"is_closed":true,"start_time":"09:00:00","end_time":"23:00:00","weekday":"Saturday"},{"is_closed":true,"start_time":"09:00:00","end_time":"23:00:00","weekday":"Sunday"}];

const TIMING_SELECTION_INIT = {"is_open_0":true,"open_time_0":"09:00","close_time_0":"23:00","is_open_1":true,"open_time_1":"09:00","close_time_1":"23:00","is_open_2":true,"open_time_2":"09:00","close_time_2":"23:00","is_open_3":true,"open_time_3":"09:00","close_time_3":"23:00","is_open_4":true,"open_time_4":"09:00","close_time_4":"23:00","is_open_5":true,"open_time_5":"09:00","close_time_5":"23:00","is_open_6":true,"open_time_6":"09:00","close_time_6":"23:00"};
// ----------------------------------------------------------------------

const initialState = {
  isLoading: true,
  error: null,
  alertError: null,
  alertSuccess: null,
  branchId: null,
  branchDetails: null,
  spaceId: null,
  spaceDetails: null,
  facilityOptions: null,
  coworkInfoOptions: null,
  countries:null,
  cities:null,
  isBranchUpdated: false,
  branch: {
    isUpdated: false,
    general: null,
    images: null,
    facilities: [],
    cowork: null,
    drinks: [],
    emails: [],
    reportingEmails: [],
    timings: {},
    policies: null,
  },
  space: {
    general: null,
    images: null,
    amenities: [],
    timings: {},
  },
};

const slice = createSlice({
  name: 'branchCreateEdit',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START UPDATING
    startUpdating(state) {
      state.isBranchUpdated = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Display Alert Success
    setAlertSuccess(state, action) {
      const message = action.payload;
      state.alertSuccess = message;
    },

    // Display Alert Error
    setAlertError(state, action) {
      const message = action.payload;
      state.alertError = message;
    },

    // Reset Alert Success
    resetAlertSuccess(state) {
      state.alertSuccess = null;
    },

    // Reset Alert Error
    resetAlertError(state) {
      state.alertError = null;
    },

    // GET BRANCH DETAILS
    getBranchDetailsSuccess(state, action) {
      // state.isLoading = false;
      const res = action.payload.context ? action.payload.context : action.payload;
      state.isDetailsLoading = false;
      // console.log(JSON.stringify(res, null,2));
      state.branchDetails = res;
    },

    // GET FACILITY OPTIONS
    getFacilityOptionsSuccess(state, action) {
      state.isDetailsLoading = false;
      state.facilityOptions = action.payload.context ? action.payload.context : action.payload;
    },

    // GET COWORK INFO OPTIONS
    getCoworkInfoOptionsSuccess(state, action) {
      state.isDetailsLoading = false;
      state.coworkInfoOptions = action.payload.context ? action.payload.context : action.payload;
    },

    // GET COUNTRIES OPTIONS
    getCountriesSuccess(state, action) {
      state.isDetailsLoading = false;
      const countryResponse = action.payload.context ? action.payload.context : action.payload;
      state.countries = countryResponse && countryResponse.sort((country1, country2) => country1.id - country2.id) || null;
    },

    // GET CITIES OPTIONS
    getCitiesSuccess(state, action) {
      state.isDetailsLoading = false;
      state.cities = action.payload.context ? action.payload.context : action.payload;
    },

    // GET SPACE DETAILS
    getSpaceDetailsSuccess(state, action) {
      state.isDetailsLoading = false;
      state.branchDetails = action.payload;
    },

    // SET DEFAULTS
    setBranchFormDefaults(state, action) {
      const details = action.payload && action.payload.context ? action.payload.context : action.payload;
      const general = {
        branchName: details?.name || '',
        branchContact: details?.phone_no || '',
        branchEmail: details?.email || '',
        branchManager: details?.branch_manger_name || '',
        branchLat: details?.lat || '',
        branchLon: details?.lon || '',
        country: details?.country_id || '',
        city: details?.location_id || '',
        branchWebsite: details?.website || '',
        is_meet: details ? details.is_meet : true,
        is_office: details ? details.is_office : true,
        is_creative: details ? details.is_creative : true,
        is_work: details ? details.is_work : false,
        venue_type: details && details.venue_type_id ? +details.venue_type_id : '',
        branchAddress: details?.address || '',
        branchDesc: details?.description || '',
      };
      general.branch_id = details?.id || 0;
      // console.log(general);

      const images = details?.media || [];
      // console.log(images);

      const facilities = details?.facilities || [];
      // console.log(facilities);

      const goodFor = details && details.good_for_ids ? details.good_for_ids : [];

      const reviewBadges = details?.review_badges_rating.map(badge=>+badge.id) || [];

      const cowork = {
        goodFor,
        reviewBadges,
        ambience: details && details.ambience_id || '',
        capacity: details?.total_capacity || 0,
        wifi_name: details && details.wifi_name || '',
        wifi_password: details && details.wifi_password || '',
        first_free_checkin: details && details.first_free_checkin || false,
      }

      const drinks = details?.recommended_bites.map(drink=>drink.bite_id.toString()) || [];


      const emails = details && details.branch_notify_emails && details.branch_notify_emails.length ? details.branch_notify_emails.split(',') : [];
      // console.log(emails);

      const reportingEmails = details && details.branch_reporting_emails && details.branch_reporting_emails.length ? details.branch_reporting_emails.split(',') : [];

      const timings = details && details.branch_timings && details.branch_timings.length ?
        details.branch_timings.reduce((previousValue, currentValue, currentIndex) => {
          const item = {};
          item[`is_open_${currentIndex}`] = !currentValue.is_closed;
          item[`open_time_${currentIndex}`] = currentValue.start_time?.slice(0, 5) || '09:00';
          item[`close_time_${currentIndex}`] = currentValue.end_time?.slice(0, 5) || '18:00';
          return {
            ...previousValue,
            ...item,
          };
        }, {}) : Object.assign(TIMING_SELECTION_INIT,{});
      const policies = {
        space_cancellation_policy: details?.space_cancellation_policy || '',
        space_checkin_instructions: details?.space_checkin_instructions || '',
        food_policy: details?.food_policy || '',
        cowork_checkin_instructions: details?.cowork_checkin_instructions || '',
        terms_and_conditions: details?.terms_and_conditions || '',
      };

      state.branchId = details?.id || 0;
      state.branch.general = general;
      state.branch.images = images;
      state.branch.facilities = facilities;
      state.branch.cowork = cowork;
      state.branch.drinks = drinks;
      state.branch.emails = emails;
      state.branch.reportingEmails = reportingEmails;
      state.branch.timings = timings;
      state.branch.policies = policies;
      // Init timing when NEW venue
      if(details && details.branch_timings && !details.branch_timings.length){
        state.branchDetails.branch_timings = Object.assign(DEFAULT_TIMING_LIST,{});
      }
      state.isLoading = false;
    },

    // CREATE BRANCH DETAILS
    createEditBranchSuccess(state, action) {
      const context = action.payload && action.payload.context ? action.payload.context : action.payload;
      state.branchId = context.branch_id;
    },

    addDrinkSuccess(state, action) {
      const context = action.payload && action.payload.context ? action.payload.context : action.payload;
      console.log('state before adding drinks', state.drinks);
      state.drinks = context.bite_id.split(',');
      console.log('new state after adding drinks', state.drinks);
    },

    updateMediaSuccess(state) {
      // const imageId = action.payload;
      const imageUpdatedIndex = -1;
      // const filteredItems = state.branch.images?.filter((_file, index) => {
      //   const result = _file.id !== imageId;
      //   if (!result) {
      //     imageUpdatedIndex = index;
      //   }
      //   return result;
      // });
      if (imageUpdatedIndex > -1) state.branch.images[imageUpdatedIndex].is_front = 1;
    },

    // BRANCH UPDATED - REFETCHed DETAILS
    branchUpdateSuccess(state) {
      state.isBranchUpdated = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setBranchFormDefaults, resetAlertSuccess, resetAlertError } = slice.actions;

// ----------------------------------------------------------------------

export function refreshBranchDetails(branchId) {
  console.log('refreshing branch details', branchId);
  return async () => {
    try {
      dispatch(slice.actions.branchUpdateSuccess());
      const response = await axios.get(URL_PLACES.branchdetails, {
        params: { branch_id: branchId },
      });
      if (response.data.status) {
        const getData = response.data.context;
        dispatch(slice.actions.getBranchDetailsSuccess(getData));
        dispatch(slice.actions.setBranchFormDefaults(getData));
        // dispatch(slice.actions.branchUpdateSuccess());
      } else {
        dispatch(
          slice.actions.setAlertError(
            response.data.error?.message || response.data.error?.title || B_DETAILS_FETCH_ERROR
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(B_DETAILS_LOAD_ERROR));
      dispatch(slice.actions.setAlertError(B_DETAILS_LOAD_ERROR));
    }
  };
}

// ----------------------------------------------------------------------

export function getBranchDetails(branchId) {
  console.log('getting branch details');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(URL_PLACES.branchdetails, {
        params: { branch_id: branchId },
      });
      if (response.data.status) {
        const getData = response.data.context;
        dispatch(slice.actions.getBranchDetailsSuccess(getData));
        dispatch(slice.actions.setBranchFormDefaults(getData));
      } else {
        dispatch(slice.actions.hasError(B_DETAILS_FETCH_ERROR));
        dispatch(
          slice.actions.setAlertError(
            response.data.error?.message || response.data.error?.title || B_DETAILS_FETCH_ERROR
          )
        );
      }
    } catch (error) {
      console.log('getBranchDetails catch error:', error);
      dispatch(slice.actions.hasError(B_DETAILS_LOAD_ERROR));
      dispatch(slice.actions.setAlertError(B_DETAILS_LOAD_ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function getFacilityOptions() {
  return async () => {
    // dispatch(slice.actions.startSpaceLoading());
    try {
      const response = await axios.get(URL_PLACES.facilityOptions);
      if (response.data.status) {
        dispatch(slice.actions.getFacilityOptionsSuccess(response.data.context));
      } else {
        dispatch(
          slice.actions.setAlertError(
            response.data.error?.message || response.data.error?.title || F_DETAILS_FETCH_ERROR
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(F_DETAILS_FETCH_ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function getCoworkInfoOptions() {
  return async () => {
    try {
      const response = await axios.get(URL_PLACES.coworkInfoOptions);
      if (response.data.status) {
        dispatch(slice.actions.getCoworkInfoOptionsSuccess(response.data.context));
      } else {
        dispatch(
          slice.actions.setAlertError(
            response.data.error?.message || response.data.error?.title || C_DETAILS_FETCH_ERROR
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(C_DETAILS_FETCH_ERROR));
    }
  }
}
// ----------------------------------------------------------------------

export function getCountries() {
  return async () => {
    try {
      const response = await axios.get(URL_PLACES.countries);
      if (response.data.status) {
        dispatch(slice.actions.getCountriesSuccess(response.data.context));
      } else {
        dispatch(
          slice.actions.setAlertError(
            response.data.error?.message || response.data.error?.title || CON_DETAILS_FETCH_ERROR
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(CON_DETAILS_FETCH_ERROR));
    }
  }
}
// ----------------------------------------------------------------------

export function getCities() {
  return async () => {
    try {
      const response = await axios.get(URL_PLACES.cities);
      if (response.data.status) {
        dispatch(slice.actions.getCitiesSuccess(response.data.context));
      } else {
        dispatch(
          slice.actions.setAlertError(
            response.data.error?.message || response.data.error?.title || CITY_DETAILS_FETCH_ERROR
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(CITY_DETAILS_FETCH_ERROR));
    }
  }
}
// ----------------------------------------------------------------------
//              SAVE
// ----------------------------------------------------------------------

export function createEditBranch(details) {
  return async () => {
    // dispatch(slice.actions.startSpaceLoading());
    try {
      const response = await axios.post(URL_PLACES.createBranch, details);
      // const response ={ data: { status: 1, context: { branch_id: 591} }} ;
      if (response.data.status) {
        if (response.data.context?.branch_id || !details.branch_id) {
          dispatch(slice.actions.createEditBranchSuccess(response.data.context));
          dispatch(slice.actions.setAlertSuccess(UPDATE_SUCCESS));
          dispatch(slice.actions.startUpdating());
        } else {
          dispatch(slice.actions.setAlertSuccess(CREATE_SUCCESS));
          dispatch(slice.actions.startUpdating());
        }


      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || response.data.error?.Error || CREATE_EDIT_ERROR) // // TODO: to remove error.Error.
        );
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(CREATE_EDIT_ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function updateCoworkInfo(branchId, details) {
  return async () => {
    try {
      const response = await axios.post(`${URL_PLACES.coworkInfo}${branchId}/`, details);
      if (response.data.status) {
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(C_UPDATE_SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || C_UPDATE_ERROR)
        );
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(C_UPDATE_ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function uploadMedia(file, branchId) {
  return async () => {
    try {
      const formData = new FormData();
      formData.append('image', file);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
        params: {
          branch_id: branchId,
        },
      };
      const response = await axios.post(URL_PLACES.media, formData, config);
      if (response.data.status) {
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(M_UPLOAD_SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || M_UPLOAD_ERROR)
        );
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(M_UPLOAD_ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function removeMedia(id, branchId) {
  return async () => {
    try {
      const response = await axios.get(URL_PLACES.media, { params: { image_id: id, is_delete: 1, branch_id: branchId } });
      if (response.data.status) {
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(M_DELETE_SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || M_DELETE_ERROR)
        );
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(M_DELETE_ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function toggleIsFrontMedia(id, branchId) {
  return async () => {
    try {
      const response = await axios.get(URL_PLACES.media, {
        params: { image_id: id, is_front: 1, branch_id: branchId },
      });
      if (response.data.status) {
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(M_ISFRONT_SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || M_ISFRONT_ERROR)
        );
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(M_ISFRONT_ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function setFacility(details) {
  console.log('setFacility', JSON.stringify(details, null, 2));
  return async () => {
    // dispatch(slice.actions.startSpaceLoading());
    try {
      const response = await axios.post(URL_PLACES.facility, details);
      if (response.data.status) {
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(F_SET_SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || F_SET_ERROR)
        );
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(F_SET_ERROR));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteFacility(details) {
  console.log('deleteFacility', JSON.stringify(details, null, 2));
  return async () => {
    // dispatch(slice.actions.startSpaceLoading());
    try {
      const response = await axios.get(`${URL_PLACES.deleteFacility}${details.branch_id}/${details.facility_id}/`);
      if (response.data.status) {
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(F_REMOVE__SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || F_REMOVE__ERROR)
        );
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(F_REMOVE__ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function setDrink(details) {
  return async () => {
    try {
      const response = await axios.post(URL_PLACES.drink, details);
      if (response.data.status) {
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(D_UPDATE_SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || D_UPDATE_ERROR)
        );
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(D_UPDATE_ERROR));
    }
  };
}

export function updateNotifyEmails(details) {
  return async () => {
    try {
      const response = await axios.post(URL_PLACES.notification, details);
      if (response.data.status) {
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(E_UPDATE__SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || E_UPDATE__ERROR)
        );
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(E_UPDATE__ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function updateTimings(details) {
  return async () => {
    try {
      const response = await axios.post(URL_PLACES.timings, details);
      if (response.data.status) {
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(T_UPDATE__SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || T_UPDATE__ERROR)
        );
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(T_UPDATE__ERROR));
    }
  };
}

// ----------------------------------------------------------------------

export function updatePolicies(details) {
  return async () => {
    try {
      const response = await axios.post(URL_PLACES.policy, details);
      if (response.data.status) {
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(P_UPDATE__SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || P_UPDATE__ERROR)
        );
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(P_UPDATE__ERROR));
    }
  };
}

import { createSlice } from '@reduxjs/toolkit';
// import sum from 'lodash/sum';
// import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
import { URL_PLACES } from '../../utils/restApiUrls';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const B_FETCH_ERROR = 'venues.error.fetchBranches';
const S_FETCH_ERROR = 'venues.error.fetchSpaces';
const CALENDAR_FETCH_ERROR = 'venues.error.fetchCalendars';
const CALENDAR_LINK_SUCCESS = 'venues.success.linkCalendar';
const CALENDAR_LINK_ERROR = 'venues.error.linkCalendar';
const S_ENABLE_SUCCESS = 'venues.success.enableSpace';
const S_ENABLE_ERROR = 'venues.error.enableSpace';
const S_DISABLE_SUCCESS = 'venues.success.disableSpace';
const S_DISABLE_ERROR = 'venues.error.disableSpace';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  alertError: null,
  alertSuccess: null,
  products: [],
  isBranchLoading: true,
  isSpaceLoading: true,
  branches: [],
  spaces: [],
  branchInfo: null,
  googleId: null,
  isCalendarOpenModal: false,
  linkCalendarSpaceId: null,
  calendarList: null,
  selectedBranch: null,
  selectedSpace: null,
  product: null,
  sortBy: null,
  isDetailsLoading: false,
  branchDetails: null,
  spaceDetails: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: '',
    rating: '',
  },
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
  },
};

const slice = createSlice({
  name: 'myPlace',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START BRANCH LOADING
    startBranchLoading(state) {
      state.isBranchLoading = true;
    },

    // START SPACE LOADING
    startSpaceLoading(state) {
      state.isSpaceLoading = true;
    },

    // START BRANCH/SPACE DETAILS LOADING
    startDetailsLoading(state) {
      state.isDetailsLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Display Alert
    setAlert(state, action) {
      const { msg, type } = action.payload;
      state.alert = msg;
      state.alertType = type;
    },

    // Reset Alert
    resetAlert(state) {
      state.alert = null;
      state.alertType = null;
    },

    // Display Alert Success
    setAlertSuccess(state, action) {
      const message = action.payload;
      state.alertSuccess = message;
    },

    // Display Alert Error
    setAlertError(state, action) {
      const message = action.payload;
      state.alertError = message;
    },

    // Reset Alert Success
    resetAlertSuccess(state) {
      state.alertSuccess = null;
    },

    // Reset Alert Error
    resetAlertError(state) {
      state.alertError = null;
    },

    // GET BRANCH LIST
    getBranchesSuccess(state, action) {
      state.isBranchLoading = false;
      state.branches = action.payload;
    },

    // SELECT BRANCH
    selectBranch(state, action) {
      state.selectedBranch = action.payload;
    },

    // GET SPACE LIST
    getSpacesSuccess(state, action) {
      state.isSpaceLoading = false;
      const { context, branch_info:branchInfo } = action.payload;
      state.spaces = context;
      state.branchInfo = branchInfo;
    },

    // GET CALENDAR LIST FROM GOOGLE
    getCalendarListSuccess(state, action) {
      const result = action.payload.context? action.payload.context: action.payload;
      const { items, space } = result;
      state.googleId = result.google_id;
      state.calendarList = {
        items: items.filter(item=>item.accessRole!=='reader'),
        ...(space && { space }),
      };
    },

    // LINK CALENDAR TARGET SPACE ID
    setLinkCalendarSpaceId(state, action) {
      state.linkCalendarSpaceId = action.payload;
    },

    // CLOSE MODAL
    openCalendarModal(state) {
      // console.log('openModal');
      state.isCalendarOpenModal = true;
    },

    // CLOSE MODAL
    closeCalendarModal(state) {
      // console.log('closeModal');
      state.isCalendarOpenModal = false;
      state.linkCalendarSpaceId = null;
      state.googleId = false;
      state.calendarList = null;
    },

    // SELECT SPACE
    selectSpace(state, action) {
      state.selectedSpace = action.payload;
    },

    // GET BRANCH DETAILS
    getBranchDetailsSuccess(state, action) {
      state.isDetailsLoading = false;
      state.branchDetails = action.payload;
    },

    // GET SPACE DETAILS
    getSpaceDetailsSuccess(state, action) {
      state.isDetailsLoading = false;
      state.spaceDetails = action.payload;
    },

    // DISABLE SPACE
    disableSpaceSuccess(state, action) {
      const spaceId = action.payload
      const spaces = [...state.spaces];
      const spaceIndex = spaces.findIndex(space => space.id === spaceId);
      spaces[spaceIndex].isEnabled = false;
      state.spaces = spaces;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetAlert,
  selectBranch,
  selectSpace,
  resetAlertError,
  resetAlertSuccess,
  openCalendarModal,
  closeCalendarModal,
} = slice.actions;
// ----------------------------------------------------------------------

export function getBranches() {
  return async () => {
    dispatch(slice.actions.startBranchLoading());
    try {
      const response = await axios.get(URL_PLACES.branches);
      if(response.data.status){
        dispatch(slice.actions.getBranchesSuccess(response.data.context));
      } else {
        dispatch(slice.actions.hasError(response.data.error?.message || response.data.error?.title || B_FETCH_ERROR));
        dispatch(slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || B_FETCH_ERROR));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(B_FETCH_ERROR));
      dispatch(slice.actions.setAlertError(B_FETCH_ERROR));
    }
  };
}

// ----------------------------------------------------------------------

export function getSpaces(branchId) {
  return async () => {
    dispatch(slice.actions.startSpaceLoading());
    try {
      const response = await axios.get(URL_PLACES.spaces, {
        params: { branch_id: branchId },
      });
      if(response.data.status){
        dispatch(slice.actions.getSpacesSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data.error?.message || response.data.error?.title || S_FETCH_ERROR));
        dispatch(slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || S_FETCH_ERROR));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(S_FETCH_ERROR));
      dispatch(slice.actions.setAlertError(S_FETCH_ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function getGoogleCalendarList(branchId, spaceId) {
  return async () => {
    const API_URL = `${URL_PLACES.calendarList}${branchId}/${spaceId}/`;
    console.log('getGoogleCalendarList>>>> API_URL::',branchId, spaceId,API_URL);
    try {
      const response = await axios.get( API_URL );
      if(response.data.status){
        dispatch(slice.actions.getCalendarListSuccess(response.data.context));
        dispatch(slice.actions.setLinkCalendarSpaceId(spaceId));
      } else {
        dispatch(slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || CALENDAR_FETCH_ERROR));
      }

    } catch (error) {
      dispatch(slice.actions.setAlertError(CALENDAR_FETCH_ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function linkCalendarSave(calendarId, calendarName, spaceId) {
  // {"space_id":"55","calendar_id":"c_vlvs605f06j6it4j0ada7n3op8@group.calendar.google.com","calendar_name":"Dow 6"}
  return async () => {
    console.log('linkCalendarSave>>>> API_URL::',calendarId, calendarName, spaceId);
    try {
      const postData = {};
      postData.space_id = spaceId.toString();
      postData.calendar_id = calendarId;
      postData.calendar_name = calendarName;
      const response = await axios.post( URL_PLACES.linkCalendar, postData);
      if(response.data.status){
        dispatch(slice.actions.closeCalendarModal());
        dispatch(slice.actions.setAlertSuccess(CALENDAR_LINK_SUCCESS));
      } else {
        dispatch(slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || CALENDAR_LINK_ERROR));
      }

    } catch (error) {
      dispatch(slice.actions.setAlertError(CALENDAR_LINK_ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function disableSpace(spaceId, enable) {
  return async () => {
    dispatch(slice.actions.startSpaceLoading());
    try {
      const response = await axios.get(`${URL_PLACES.disableSpace}${spaceId}/`, {
        params: {
          ...(enable && { enable: 1} || {})
        },
      });
      if (response.data.status) {
        dispatch(slice.actions.disableSpaceSuccess(spaceId));
        dispatch(slice.actions.setAlertSuccess(enable && S_ENABLE_SUCCESS || S_DISABLE_SUCCESS));
      } else {
        dispatch(slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || (enable && S_ENABLE_ERROR || S_DISABLE_ERROR)));
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError((enable && S_ENABLE_ERROR || S_DISABLE_ERROR)));
    }
  };
}

import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from '../utils/axios';
import { setSession } from '../utils/jwt';
import { URL_AUTH } from '../utils/restApiUrls';
import { TRACK_SIGNUP, TRACK_LOGIN, TRACK_LOGOUT } from '../utils/trackAnalytics';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  wifi: 0,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  UUID: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      user,
    }
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    const userType = user?.user_type || 0;
    window.localStorage.setItem('lwt_ut',userType);
    return {
      ...state,
      isAuthenticated: true,
      user: {...state.user, ...user},
    };
  },
  NEW_SOCIAL_LOGIN: (state, action) => {
    const { user } = action.payload;
    window.localStorage.setItem('lwt_ut',0);
    return {
      ...state,
      user,
    }
  },
  SOCIAL_LOGIN: (state, action) => {
    const { user } = action.payload;
    window.localStorage.setItem('lwt_ut',0);
    return {
      ...state,
      isAuthenticated: true,
      user: {...state.user, ...user},
    };
  },
  LOGOUT: (state) =>  {
    window.localStorage.setItem('lwt_ut',0);
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  },
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  WIFISPEED: (state, action) => {
    const { wifi } = action.payload;

    return {
      ...state,
      wifi,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  finishNormalSignUp: () => Promise.resolve(),
  login2: () => Promise.resolve(),
  verifyOTPLogin: () => Promise.resolve(),
  socialLogin: () => Promise.resolve(),
  verifyOTP: () => Promise.resolve(),
  resendOTP: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  setPassword: () => Promise.resolve(),
  finishSignUp: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  wifiUpdate: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      if(process.env.REACT_APP_USE_MOCK === 2){
        console.log('pass through.....')
        setSession('1234');
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: {
              name: 'Mock User',
              email: 'mock.user@letswork.io',
              user_id: '1234',
              x_token: 'mockusertesting'
            },
          },
        });
      } else {
        try {
          const accessToken = window.localStorage.getItem('accessToken');

          if (accessToken ) { // && isValidToken(accessToken)
            setSession(accessToken);

            const response = await axios.get(URL_AUTH.validate);
            const { status, context } = response.data;
            if(context){
              // eslint-disable-next-line camelcase
              const { user_type } = context;
              window.localStorage.setItem('lwt_ut',user_type);
            }

            if(status){
              dispatch({
                type: 'INITIALIZE',
                payload: {
                  isAuthenticated: true,
                  user: context,
                },
              });
            } else {
              setSession(null);
              dispatch({
                type: 'INITIALIZE',
                payload: {
                  isAuthenticated: false,
                  user: null,
                },
              });
            }

          } else {
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
          }
        } catch (err) {
          console.error(err);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });

        }
      }
    };

    initialize();
  }, []);

  const login = async (emailOrMobile, password, token) => {
    const email = emailOrMobile.toString().includes("@") ? emailOrMobile : null;
    const mobile = emailOrMobile.toString().includes("@") ? null : emailOrMobile;
    const memberNotAllowed = { member_not_allowed: 1 };
    const body = email ? { email, password, token, ...memberNotAllowed } : { mobile, password, token, ...memberNotAllowed };
    const response = await axios.post(URL_AUTH.login, body);
    const { status, context } = response.data;
    console.log(`${URL_AUTH.login}\nResponse:`, JSON.stringify(response.data));

    if(status){
      if (response.data['Signup uuid']) {
        const payload = {
          email,
          mobile,
          password,
          signupUuid: response.data['Signup uuid']
        }
        dispatch({
          type: 'UUID',
          payload: {
            user: payload
          }
        })
      } else {
        setSession(context.x_token);
        dispatch({
          type: 'LOGIN',
          payload: {
            user: context,
          },
        });
        TRACK_LOGIN(context);
      }

    } else {
      setSession(null);
    }

    return response.data;
  };


  const login2 = async (email, token) => {
    const memberNotAllowed = { member_not_allowed: 1 };
    const body = { email, token, ...memberNotAllowed };
    const response = await axios.post(URL_AUTH.login2, body);
    const { status, context } = response.data;
    console.log(`${URL_AUTH.login}\nResponse:`, JSON.stringify(response.data));

    if(status){
      if (response.data.signup_uuid) {
        const payload = {
          email,
          signupUuid: response.data.signup_uuid,
        }
        dispatch({
          type: 'UUID',
          payload: {
            user: payload
          }
        })
      }
    } else {
      setSession(null);
    }

    return response.data;
  };

  const finishNormalSignUp = async (data) => {
    const response = await axios.post(URL_AUTH.finishVenueSignUp, {
      ...data,
    });
    setSession(null);

    console.log(
      `${URL_AUTH.finishVenueSignUp}\nResponse:`,
      JSON.stringify(response.data)
    );

    const { status, data:context, message, is_new_user:isNewFlag } = response.data;

    if(status){
      TRACK_SIGNUP({ userId: context.user_id, user: context })
      setSession(context.x_token);
      const isNew = isNewFlag || context?.user_landing_url === "/welcome/"
      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            ...context,
            isNew,
          },
        },
      });

    } else {
      setSession(null);
    }
    return { status, context, error:{ message }, isNewFlag };
  };

  const verifyOTPLogin = async (data) => {
    const response = await axios.post(URL_AUTH.verifyOTPLogin, data);

    const { status, context, is_new_user:isNewFlag } = response.data;

    if (status) {
      console.log("login OTP verified");
      TRACK_LOGIN({ userId: context.user_id, user: context });
      setSession(context.x_token);
      const isNew = isNewFlag || context?.user_landing_url === "/letsmeet/welcome/"
      dispatch({
        type: "LOGIN",
        payload: {
          user: {
            ...context,
            isNew,
          },
        },
      });
    } else {
      setSession(null);
    }
    return response.data;
  };

  const signUp = async (data) => {
    const response = await axios.post(URL_AUTH.signup, {
      'user_type': '12',
      ...data,
      'location_id': 2,
    })
    setSession(null);
    const { status, context, error } = response.data;

    if(status){
      if (response.data.signup_uuid) {
        const payload = {
          ...data,
          signupUuid: response.data.signup_uuid,
        }
        dispatch({
          type: 'UUID',
          payload: {
            user: payload
          }
        })
      }
      
    } else {
      setSession(null);
      return { error: error.message || error.title || 'Signup failed'};
    }
    return response.data;
  }

  const verifyOTP = async (data) => {
    const response = await axios.post(URL_AUTH.verifyOTP, data)

    const { status } = response.data;

    if(status) {
      console.log('OTP verified');
    } else {
      setSession(null);
    }
    return response.data;
  }

  const resendOTP = async (data) => {
    const response = await axios.post(URL_AUTH.resendOTP, data)

    return response.data;
  }

  const resetPassword = async (data) => {
    const response = await axios.post(URL_AUTH.resetPassword, { email_id: data.email, source: 'web' });

    return response.data;
  }

  const setPassword = async (data) => {
    const response = await axios.post(URL_AUTH.setPassword, {  ...data, source: 'web' });

    return response.data;
  }

  const finishSignUp = async (data) => {
    const {email, mobile, name, password, signupUuid, country} = data;
    const response = await axios.post(URL_AUTH.finishSignUp, {
      'user_type': '10',
      ...data,
      // 'name': name,
      // 'email': email,
      // 'mobile': mobile,
      // 'password': password,
      // 'signup_uuid': signupUuid,
      // 'country_id': country,
      'location_id': 2,
    })
    setSession(null);

    console.log(`${URL_AUTH.finishSignUp}\nResponse:`, JSON.stringify(response.data));

    const { status, error } = response.data;

    if(!status) {
      setSession(null);
      console.log('finish signup failed');
      return { error: error.message || error.title || 'Signup failed'};
    }
    TRACK_SIGNUP(data);
    return { error: null };
  }

  const socialLogin = async (data) => {
    let isNewUser;
    let errorResponse = {};
    const response = await axios.post(URL_AUTH.socialLogin, { source: data.source, email: data.email, id: data.id});

    // eslint-disable-next-line camelcase
    const { status, context, error, is_new_user } = response.data;

    if(status) {
      // eslint-disable-next-line camelcase
      if (is_new_user) {
        isNewUser = true;
        dispatch({
          type: 'NEW_SOCIAL_LOGIN',
          payload: {
            user: { ...data, profile_image: data.picture },
          }
        })
      } else {
        setSession(context.x_token);
        dispatch({
          type: 'SOCIAL_LOGIN',
          payload: {
            user: context,
          },
        });
        TRACK_LOGIN(context);
      }
    } else {
      errorResponse = error;
      setSession(null);
      console.log('login failed section');
    }

    return { isNewUser, errorResponse };
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    TRACK_LOGOUT();
    axios.get(URL_AUTH.logout);
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const wifiUpdate = async (speed) => {
    // console.log("wifiUpdate", speed, (new Date).getTime())
    dispatch({
      type: 'WIFISPEED',
      payload: {
        wifi: speed
      },

    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        login2,
        verifyOTPLogin,
        signUp,
        finishNormalSignUp,
        socialLogin,
        verifyOTP,
        resendOTP,
        resetPassword,
        setPassword,
        finishSignUp,
        logout,
        register,
        wifiUpdate,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  hideText: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, hideText, sx  }) {
  const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;
  // fill="#000000"

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="100%" height="100%" viewBox="0 0 400.000000 400.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)" fill={PRIMARY_MAIN} stroke="none">
          <path d="M1480 3440 l-535 -309 -3 -1191 -2 -1191 92 50 c51 27 275 151 498 274 l405 224 3 -544 c1 -300 6 -543 10 -541 5 2 252 171 550 377 l542 373 0 498 0 498 -505 298 -505 299 0 598 c0 328 -3 597 -7 596 -5 0 -249 -139 -543 -309z m460 -362 l-1 -523 -451 -260 c-249 -143 -456 -261 -460 -263 -4 -2 -8 232 -8 520 l0 523 453 261 c248 144 455 262 460 263 4 1 7 -234 7 -521z m-6 -1015 c-3 -214 -9 -431 -13 -483 l-6 -96 -417 231 c-229 126 -419 232 -422 235 -5 6 841 499 857 500 5 0 5 -174 1 -387z m521 141 c360 -214 413 -248 398 -259 -49 -33 -845 -506 -846 -503 -6 12 17 1008 24 1008 4 0 195 -111 424 -246z m497 -751 l-3 -448 -453 -313 c-250 -171 -457 -312 -460 -312 -3 0 -5 219 -4 487 l3 487 455 273 c250 149 457 272 460 272 3 1 4 -200 2 -446z m-1484 183 c238 -131 434 -242 437 -246 5 -7 -841 -481 -872 -488 -11 -3 -13 83 -13 488 0 270 4 490 8 488 5 -1 203 -110 440 -242z"/>
        </g>
      </svg>
    </Box>
  );
  const fullLogo = (
    <Box sx={{ width: 100, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="100%" height="100%" viewBox="0 0 1397.000000 411.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,411.000000) scale(0.100000,-0.100000)" fill={PRIMARY_MAIN} stroke="none">
          <path d="M743 3622 l-582 -337 -1 -1297 0 -1297 32 18 c115 66 1048 581 1053 581 3 0 5 -266 5 -591 0 -325 4 -589 8 -587 5 1 272 185 595 408 l586 405 -2 543 -2 544 -547 325 -548 326 0 648 c0 357 -3 649 -7 648 -5 -1 -270 -152 -590 -337z m507 -390 l0 -568 -491 -285 c-270 -156 -495 -285 -500 -287 -5 -2 -9 238 -9 566 l0 569 493 285 c270 157 495 286 500 287 4 1 7 -255 7 -567z m-4 -814 c-3 -73 -9 -310 -12 -525 -4 -221 -10 -393 -15 -393 -5 0 -214 113 -464 252 -286 158 -451 255 -443 260 186 110 925 537 932 537 5 1 6 -58 2 -131z m607 -168 c218 -129 396 -238 397 -241 0 -6 -898 -548 -923 -558 -10 -4 -3 708 9 972 l7 127 57 -32 c31 -18 235 -139 453 -268z m492 -1197 l-7 -81 -486 -337 c-268 -186 -493 -340 -499 -342 -10 -4 -13 105 -13 525 l0 529 503 302 502 301 3 -408 c2 -224 0 -444 -3 -489z m-1609 607 c258 -144 472 -264 476 -268 6 -6 -224 -136 -884 -500 l-78 -43 0 535 c0 295 4 536 8 536 5 0 220 -117 478 -260z"/>
          <path d="M3330 2050 l0 -1010 110 0 110 0 0 1010 0 1010 -110 0 -110 0 0 -1010z"/>
          <path d="M12630 2050 l0 -1010 70 0 70 0 0 173 0 172 168 168 c92 92 170 167 172 167 3 0 129 -153 280 -340 l275 -339 88 -1 89 0 -84 102 c-274 335 -548 678 -548 685 0 4 138 146 308 316 l307 308 -95 -3 -95 -3 -430 -444 -430 -445 -3 752 -2 752 -70 0 -70 0 0 -1010z"/>
          <path d="M5540 2690 l0 -220 -100 0 -100 0 0 -95 0 -95 100 0 100 0 0 -472 c0 -276 5 -491 10 -516 25 -108 93 -196 184 -238 116 -53 297 -54 416 -3 l50 21 0 89 c0 49 -3 89 -7 89 -5 -1 -33 -9 -63 -20 -37 -13 -82 -19 -136 -20 -70 0 -89 4 -135 28 -42 22 -58 38 -79 77 l-25 49 -3 458 -3 458 228 2 228 3 3 93 3 92 -230 0 -231 0 -2 218 -3 217 -102 3 -103 3 0 -221z"/>
          <path d="M4362 2480 c-229 -60 -415 -248 -485 -492 -78 -267 -15 -564 161 -758 267 -296 776 -300 1059 -9 l53 54 -65 59 -66 58 -40 -37 c-112 -101 -234 -153 -379 -162 -258 -16 -465 142 -525 401 -9 37 -13 71 -10 76 4 6 223 10 572 10 l566 0 -6 113 c-19 358 -201 613 -493 687 -101 26 -242 26 -342 0z m299 -179 c118 -37 207 -119 264 -242 28 -62 55 -161 55 -201 l0 -28 -454 0 c-279 0 -457 4 -461 10 -9 14 23 138 51 200 100 220 327 328 545 261z"/>
          <path d="M6825 2494 c-178 -28 -324 -130 -376 -262 -31 -79 -32 -216 -2 -283 57 -126 151 -183 455 -275 288 -87 354 -139 346 -269 -5 -88 -37 -134 -121 -175 -152 -74 -389 -37 -594 95 -37 24 -70 42 -74 39 -13 -7 -99 -132 -99 -142 0 -6 24 -26 53 -45 320 -211 729 -222 927 -24 150 150 158 393 16 530 -60 59 -154 106 -311 157 -289 93 -346 121 -393 190 -34 51 -28 135 15 187 104 130 336 131 586 4 55 -29 78 -36 85 -28 21 28 82 135 82 145 0 19 -185 102 -283 127 -95 24 -250 38 -312 29z"/>
          <path d="M10499 2479 c-154 -21 -296 -93 -414 -213 -92 -92 -150 -192 -187 -322 -29 -102 -31 -293 -4 -394 69 -256 266 -455 513 -521 107 -29 269 -29 374 -1 206 55 364 186 464 384 61 122 78 209 73 366 -4 101 -10 143 -31 207 -78 238 -272 421 -507 478 -86 21 -200 27 -281 16z m252 -142 c93 -28 164 -72 240 -147 127 -125 183 -262 183 -450 0 -178 -58 -324 -174 -440 -112 -112 -238 -164 -400 -164 -124 0 -212 27 -314 95 -394 263 -338 909 95 1085 32 13 83 28 112 34 65 11 199 5 258 -13z"/>
          <path d="M12300 2470 c-84 -11 -138 -28 -215 -68 -88 -45 -215 -170 -273 -267 l-42 -70 0 193 0 192 -70 0 -70 0 0 -705 0 -705 70 0 70 0 0 339 c0 365 6 427 53 553 34 89 87 170 153 234 94 90 245 154 363 154 l51 0 0 80 c0 90 8 83 -90 70z"/>
          <path d="M8646 2451 c-3 -5 -98 -280 -212 -612 -113 -332 -209 -607 -213 -611 -3 -4 -100 270 -216 607 l-210 615 -77 0 -77 0 37 -103 c20 -57 136 -377 257 -710 l220 -607 61 0 61 0 209 599 c115 330 211 597 214 594 3 -2 98 -271 211 -596 l206 -592 60 -3 c44 -2 62 1 66 11 31 81 507 1396 507 1401 0 4 -33 6 -72 4 l-73 -3 -210 -612 c-115 -337 -213 -609 -216 -605 -4 4 -99 279 -213 610 -113 332 -209 608 -212 613 -7 12 -101 12 -108 0z"/>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  if (hideText) {
    return <RouterLink to="/"><>{logo}</></RouterLink>;
  }

  return <RouterLink to="/"><>{fullLogo}</></RouterLink>;
}

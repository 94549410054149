import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import overviewReducer from './slices/overview';
import myPlaceReducer from './slices/myPlace';
import calendarReducer from './slices/calendar';
import branchCreateEditReducer from './slices/branchCreateEdit';
import spaceCreateEditReducer from './slices/spaceCreateEdit';
import salesReducer from './slices/sales';
import checkInsSalesReducer from './slices/coworkSales';
import coworkCustomersReducer from './slices/coworkCustomers';
import settingsReducer from './slices/settings';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const myPlacePersistConfig = {
  key: 'myPlace',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const appReducer = combineReducers({
  /* your app’s top-level reducers */
  overview: overviewReducer,
  sales: salesReducer,
  coworkSales: checkInsSalesReducer,
  coworkCustomers: coworkCustomersReducer,
  settings: settingsReducer,
  calendar: calendarReducer,
  branchCreateEdit: branchCreateEditReducer,
  spaceCreateEdit: spaceCreateEditReducer,
  myPlace: persistReducer(myPlacePersistConfig, myPlaceReducer),
})

const rootReducer = (state, action) => {
  console.log(action.type)
  if (action.type === 'settings/logoutResetReduxStates') {
    console.log(state, action)
    // for all keys defined in your persistConfig(s)
    // storage.removeItem('persist:root')
    window.localStorage.removeItem('redux-root')
    window.localStorage.removeItem('redux-myPlace')
    window.localStorage.removeItem('redux-product')
    window.localStorage.removeItem('redux-myPlace')

    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export { rootPersistConfig, rootReducer };

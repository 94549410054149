import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography, Alert, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import useAuth from '../../../hooks/useAuth';
import useLocales from '../../../hooks/useLocales';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';

const GENERAL_ERROR = 'Could not process this request.';

const CornerCutShapeStyle = styled(Box)(()=>({
  '-webkit-clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 17% 100%, 0% 75%)',
  'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 17% 100%, 0% 75%)',
}));

OTP.propTypes = {
  changeScreen: PropTypes.func,
  defaultScreen: PropTypes.string,
};

export default function OTP({ changeScreen, defaultScreen }) {
  const { Translate } = useLocales();
  const navigate = useNavigate();
  const [otp, setOtp] = useState({ otp1: '', otp2: '', otp3: '', otp4: '' });
  const [resendTimeout, setResendTimeout] = useState(5);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [resendClickCount, setResendClickCount] = useState(0);
  const [showResendText, setShowResendText] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const otpRef1 = useRef(null);
  const otpRef2 = useRef(null);
  const otpRef3 = useRef(null);
  const otpRef4 = useRef(null);

  const { verifyOTP, resendOTP, user, finishNormalSignUp, verifyOTPLogin } = useAuth();

  const OTPSchema = Yup.object().shape({
    otp1: Yup.number().typeError(''),
    otp2: Yup.number().typeError(''),
    otp3: Yup.number().typeError(''),
    otp4: Yup.number().typeError(''),
  });

  const defaultValues = {
    // otp1: 1,
    // otp2: 2,
    // otp3: 3,
    // otp4: 4,
  };

  const methods = useForm({
    resolver: yupResolver(OTPSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    console.log('OTP onSubmit');
    setError(false);
    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise.execute('6Ldr-KogAAAAAB1Qt2leeQriuqfmAYAwxESSEQvr', {action: 'login'}).then(async (token) => {
        const OTP_JOIN = `${otp.otp1}${otp.otp2}${otp.otp3}${otp.otp4}`
        const postData = { 
          email: user.email,
          signup_uuid: user.signupUuid,
          otp: OTP_JOIN, 
          token,
          form_type: "branch_manager_registration",
        };
        try {
          if(defaultScreen === 'finishSignUp'){
            const finishSignUpPostData = {
              ...postData,
              name: user.name,
              mobile: user.mobile,
              source: 2,
              // city_id:  ipCity?.id,
              // country_id: ipCity?.country?.id,
              // currency_id: ipCity?.country?.currency.id,
            };

            const finishSignUpResponse = await finishNormalSignUp(
              finishSignUpPostData
            );
            if (finishSignUpResponse.error?.message || 0) {
              setError('afterSubmit', finishSignUpResponse.error);

            }  else if (finishSignUpResponse.context?.x_token) {
              setSuccess('Signup successful');

            } else if(!finishSignUpResponse.status){
              setError('afterSubmit', { message: GENERAL_ERROR });
            }

          } else if(defaultScreen === 'login'){
            const responseData = await verifyOTPLogin(postData);
            const _isNewUser = responseData.is_new_user;
            if (responseData.error?.message || 0) {
              setError('afterSubmit', responseData.error);

            }  else if (responseData.context?.x_token) {
              // logged in successfully

            } else if(!responseData.status){
              setError('afterSubmit', { message: GENERAL_ERROR });
            }   

          }
        } catch (error) {
          console.log(error);
          setError({ message: error });
        }
        
      });
    })
  };

  const handleResendOTP = async () => {
    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise.execute('6Ldr-KogAAAAAB1Qt2leeQriuqfmAYAwxESSEQvr', {action: 'login'}).then(async (token) => {
        setResendDisabled(true);
        if (resendClickCount === 2) {
          setShowResendText(false);
        }
        const initialTimeout = 30;
        setResendTimeout(initialTimeout * (resendClickCount + 1));
        setResendClickCount(resendClickCount + 1);

        const postData = { 'signup_uuid': user.signupUuid, token };
        const data = await resendOTP(postData);
        if (data.status) {
          setSuccess({ title: 'Success', message: 'OTP sent' });
        } else if (data.error.message) {
          setError(data.error);
        } else {
          setError({ title: 'Error', message: 'Error while sending OTP' });
        }

        setTimeout(() => {
          if (data.status) {
            setSuccess(false);
          } else {
            setError(false);
          }
        }, 5000);
      });
    })
  };

  const handleOtpChange = (event) => {
    if (event.target.value.length) {
      switch (event.target.name) {
        case 'otp1':
          setOtp({ ...otp, otp1: event.target.value });
          otpRef2.current.focus();
          break;
        case 'otp2':
          setOtp({ ...otp, otp2: event.target.value });
          otpRef3.current.focus();
          break;
        case 'otp3':
          setOtp({ ...otp, otp3: event.target.value });
          otpRef4.current.focus();
          break;
        case 'otp4':
          setOtp({ ...otp, otp4: event.target.value });
          break;
        default:
      }
    } else {
      switch (event.target.name) {
        case 'otp1':
          setOtp({ ...otp, otp1: event.target.value });
          break;
        case 'otp2':
          setOtp({ ...otp, otp2: event.target.value });
          otpRef1.current.focus();
          break;
        case 'otp3':
          setOtp({ ...otp, otp3: event.target.value });
          otpRef2.current.focus();
          break;
        case 'otp4':
          setOtp({ ...otp, otp4: event.target.value });
          otpRef3.current.focus();
          break;
        default:
      }
    }
  };

  const digitStyles = {
    mx: 1,
    width: '58px',
    '& .MuiOutlinedInput-input': {
      borderRadius: '25px',
      textAlign: 'center',
      fontSize: '32px',
      height: '48px',
    },
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setResendTimeout(resendTimeout - 1);
    }, 1000);
    if (resendTimeout === 0) {
      setResendDisabled(false);
      clearTimeout(timeout);
    }
  }, [resendTimeout]);

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {!!error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error.message}
          </Alert>
        )}
        {!!success && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {success.message}
          </Alert>
        )}
        <Stack direction="row" justifyContent="center" sx={{ my: 4 }}>
          <RHFTextField
            name="otp1"
            sx={digitStyles}
            // value={otp.otp1}
            onChange={handleOtpChange}
            inputRef={otpRef1}
            inputProps={{ maxLength: 1, autoFocus: true }}
          />
          <RHFTextField
            name="otp2"
            sx={digitStyles}
            // value={otp.otp2}
            onChange={handleOtpChange}
            inputRef={otpRef2}
            inputProps={{ maxLength: 1 }}
          />
          <RHFTextField
            name="otp3"
            sx={digitStyles}
            // value={otp.otp3}
            onChange={handleOtpChange}
            inputRef={otpRef3}
            inputProps={{ maxLength: 1 }}
          />
          <RHFTextField
            name="otp4"
            sx={digitStyles}
            // value={otp.otp4}
            onChange={handleOtpChange}
            inputRef={otpRef4}
            inputProps={{ maxLength: 1 }}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ pt: 4, mb: 4 }}>
          
          <Stack direction="row" alignItems="baseline">
            <Typography>
              <Translate i18nKey='login.otpInfo' />
            </Typography>
            {showResendText ? (
              <LoadingButton
                onClick={handleResendOTP}
                disabled={resendDisabled}
                sx={{
                  fontSize: '1rem',
                  fontWeight: '200',
                }}
              >
                {/* Resend OTP &#40;0:{resendTimeout.toString().padStart(2, '0') || ''}&#41; Resend OTP{' '} */}
                <Translate i18nKey='login.otpResend' />
                {resendTimeout ? `(0:${resendTimeout.toString().padStart(2, '0')})` : ''}
              </LoadingButton>
            ) : (
              <span>
                &nbsp;<u><Translate i18nKey='login.chatNow' /></u>
              </span>
            )}
          </Stack>

          <CornerCutShapeStyle sx={{ mb: 2, position: 'relative'}}>
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              <Translate i18nKey='login.btn-verify' />
            </LoadingButton>
          </CornerCutShapeStyle>
        </Stack>
      </FormProvider>
      <Stack direction="row" alignItems="center" justifyContent="space-around">
        <Typography
          onClick={() => changeScreen('login')}
          sx={{
            mt: 4,
            color: 'primary.main',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          <Translate i18nKey='login.backToSignUp' />
        </Typography>
      </Stack>
    </>
  );
}

import { useState, useEffect } from 'react';
// @mui
// import { Button } from '@mui/material';
// config
// import { ROLE_BASED_DISPLAY } from '../../config';
// hooks
import useAuth from '../../hooks/useAuth';
// utils
import axios from '../../utils/axios';
import { URL_GLOBAL } from '../../utils/restApiUrls';
//
// import RoleBasedDisplay from '../../guards/RoleBasedDisplay';
import ReactInternetSpeedMeter from './ReactInternetSpeedMeter'
import './style.css';

// ----------------------------------------------------------------------
const VERY_LONG_PING_INTERVAL = 4000000000000000000000000000000000000000000;
// ----------------------------------------------------------------------


export default function InternetSpeed() {
  const { isAuthenticated, wifi, wifiUpdate } = useAuth();
  //
  const [wifiSpeed, setwifiSpeed] = useState(0);
  const [pingInterval, setPingInterval] = useState(4000);
  // const [wifiSpeed2, setwifiSpeed2] = useState(0);
  // const DEBUG = sessionStorage.getItem("letdev") === '1';

  // useEffect(() => {
  //   console.log("speed >>>>>>\t", pingInterval, wifi, wifiSpeed); // , wifiSpeed2
  // }, [wifiSpeed]);

  useEffect(() => {
    // if wifi not updated even once also wifiSpeed is not invalid
    console.log("wifi useEffect",wifi);
    if(wifi === 0 && wifiSpeed > 0){

      wifiUpdate(wifiSpeed); // update on global tokencontext
      // backend api
      console.log("WiFi UPDATE >>>>>>\t" , pingInterval, wifi, wifiSpeed);
      const postData = {
        // "branch_id": 15,
        "wifi_speed": wifiSpeed,
        "wifi_name": "",
        "connected_to_wifi": 1
      }
      const queryParams = {
        platform: "web"
      }
      console.log(JSON.stringify(postData, null, 2));
      // console.log(JSON.stringify(queryParams, null, 2));
      axios.post(`${URL_GLOBAL.wifi}`, { ...postData }, { params: queryParams});
      if(pingInterval < 5000){
        setPingInterval(VERY_LONG_PING_INTERVAL);
      }
    }
    return () => {
      // setwifiSpeed(0)
    }
  }, [wifi, wifiSpeed, pingInterval, wifiUpdate]);

  if (!isAuthenticated) {

    if(pingInterval > 5000){
      setPingInterval(4000);
    }

    return <></>;
  }
  return (
    <>
    {
      /*
       * NOTE.
       * pingInterval       milliseconds
       * thresholdUnit      "byte" , "kilobyte", "megabyte"
       * downloadSize       bytes
       *
       */
    }

    {
      /* pingInterval !== VERY_LONG_PING_INTERVAL && */ (
        <>
        <div key={pingInterval}>
        <ReactInternetSpeedMeter
            txtSubHeading="Internet is too slow"
            outputType="alert"
            customClassName={null}
            txtMainHeading="Opps..."
            pingInterval={pingInterval}
            thresholdUnit='megabyte'
            threshold={0}
            imageUrl="https://letsworkweb.s3.us-east-2.amazonaws.com/lw_wifi_ud_check.jpg"
            downloadSize="1042592"
            callbackFunctionOnNetworkDown={(speed)=>{
              console.log(`Internet speed is down ${speed}`);
              // setPingInterval(4000);
            }}
            callbackFunctionOnNetworkTest={(speed)=>setwifiSpeed(Math.round(+speed))}
        />
        { /*
          (
            <RoleBasedDisplay accessibleRoles={ROLE_BASED_DISPLAY.ALL}>
              <ReactInternetSpeedMeter
                  txtSubHeading="Internet is too slow"
                  outputType="alert"
                  customClassName={null}
                  txtMainHeading="Opps..."
                  pingInterval={pingInterval}
                  thresholdUnit='megabyte'
                  threshold={0}
                  imageUrl="/assets/letswork.jpg"
                  downloadSize="244887"
                  callbackFunctionOnNetworkDown={(speed)=>{
                    console.log(`Internet speed is down ${speed}`);
                    // setPingInterval(4000);
                  }}
                  callbackFunctionOnNetworkTest={(speed)=>setwifiSpeed2(speed)}
              />
              <div className="alertDangerContainer">
                  <span className="alertDangerHeading"
                  style={{
                      display: 'flex'
                  }}>

                    <span className="px-16 mx-16"> WiFi S3 {wifiSpeed}Mbps, code {wifiSpeed2}Mbps </span>
                    <span className="px-8 mx-auto"
                    style={{
                      display: 'flex',
                      flexGrow: 1
                    }}/>
                    {
                      wifi && (
                        <button type="button" className="alertButton"
                        onClick={()=>{
                          alert(`helloworld ${pingInterval} ${wifi} ${wifiSpeed} ${wifiSpeed2}`)
                          const save = {
                            wifi: 0
                          }
                          setwifiSpeed(0);
                          setwifiSpeed2(0);
                          wifiUpdate(0);
                          setPingInterval(1000)
                        }}>
                          Refresh
                        </button>
                      ) || ''
                    }
                  </span>
              </div>
            </RoleBasedDisplay>
          ) */
        }
        </div>
        </>
      )
    }

    </>
  )
}

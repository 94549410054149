import { createSlice } from '@reduxjs/toolkit';
import { startOfMonth, startOfYear, endOfYear, setYear, addMonths, endOfMonth } from 'date-fns';
// utils
import axios from '../../utils/axios';
import { fDisplay } from '../../utils/formatTime';
import { URL_COWORK_SALES } from '../../utils/restApiUrls';
//
import { dispatch } from '../store';

const TABLE_FETCH_ERROR = 'sales.error.fetchWorkSales';

const initialState = {
  isLoading: true,
  isTableUpdating: false,
  error: null,
  alertError: null,
  checkInsSalesData: null,
  duration: 'monthLast',
  dateRange: null,
};

const slice = createSlice({
  name: 'coworkSales',
  initialState,
  reducers: {
    startCoworkLoading(state) {
      state.isLoading = true;
    },

    startCoworkTableUpdating(state) {
      state.isTableUpdating = true;
    },

    hasCheckInsError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setCheckInsAlertError(state, action) {
      state.alertError = action.payload;
    },

    getCheckInsSalesDataSuccess(state, action) {
      state.isLoading = false;
      state.isTableUpdating = false;
      const { context } = action.payload;
      if (Object.keys(context).length) {
        state.checkInsSalesData = context;
      } else {
        state.checkInsSalesData = {};
      }
    },

    getCheckInsSalesDataError(state) {
      state.isLoading = false;
      state.isTableUpdating = false;
    },

    updateCoworkDuration(state, action) {
      const newDuration = action.payload;
      state.duration = newDuration;
      if (newDuration !== 'custom') {
        state.dateRange = null;
      }
    },

    updateCustomCoworkDuration(state, action) {
      state.dateRange = action.payload;
    },
  },
});

export default slice.reducer;

export const { updateCoworkDuration, updateCustomCoworkDuration } = slice.actions;

export function getCheckInsData(durationLabel, dateRange) {
  return async () => {
    dispatch(slice.actions.startCoworkTableUpdating());

    try {
      const response = await axios.get(URL_COWORK_SALES.list, {
        params: {
          ...getRange(durationLabel, dateRange),
          ...((dateRange && dateRange.start && { start_date: dateRange.start_date, end_date: dateRange.end_date }) ||
            {}),
        },
      });
      if (response.data.status) {
        const getData = response.data;
        dispatch(slice.actions.getCheckInsSalesDataSuccess(getData));
      } else {
        dispatch(slice.actions.getCheckInsSalesDataError());
        dispatch(slice.actions.hasCheckInsError(response.data.error?.title || TABLE_FETCH_ERROR));
        dispatch(slice.actions.setCheckInsAlertError(response.data.error?.title || TABLE_FETCH_ERROR));
      }
    } catch (error) {
      dispatch(slice.actions.getCheckInsSalesDataError());
      dispatch(slice.actions.hasCheckInsError(TABLE_FETCH_ERROR));
      dispatch(slice.actions.setCheckInsAlertError(TABLE_FETCH_ERROR));
    }
  };
}

// ----------------------------------------------------------------------
//              helper
// ----------------------------------------------------------------------

function getRange(durationLabel, dateRange) {
  const NOW = new Date();
  const DATE_FORMAT = 'yyyy-MM-dd';
  const duration = dateRange && dateRange.start ? 'custom' : durationLabel;
  const range = {
    start_date: null,
    end_date: fDisplay(NOW, DATE_FORMAT),
  };
  const lastMonthDate = addMonths(new Date(), -1);
  switch (duration) {
    case 'monthLast':
      range.start_date = startOfMonth(lastMonthDate);
      range.end_date = fDisplay(endOfMonth(lastMonthDate), DATE_FORMAT);
      break;
    case 'month':
      range.start_date = startOfMonth(NOW);
      range.end_date = fDisplay(endOfMonth(NOW), DATE_FORMAT);
      break;
    case 'year':
      range.start_date = startOfYear(NOW);
      range.end_date = fDisplay(endOfYear(NOW), DATE_FORMAT);
      break;
    case 'day':
      range.start_date = NOW;
      break;
    case 'all':
      range.start_date = setYear(startOfYear(NOW), 1970);
      range.end_date = fDisplay(addMonths(NOW, 3), DATE_FORMAT);
      break;
    case 'custom':
    default:
      break;
  }
  try {
    range.start_date = fDisplay(range.start_date, DATE_FORMAT);
  } catch (e) {
    //
  }

  return {
    ...((range.start_date && range) || {}),
  };
}

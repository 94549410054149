import { format, parseISO, addDays, addMonths, addWeeks, getTime, formatDistanceToNow } from 'date-fns';
import enLocale from 'date-fns/locale/en-GB';
import arLocale from 'date-fns/locale/ar-SA';
import ptLocale from 'date-fns/locale/pt';
// ----------------------------------------------------------------------

const LOCALES = { en: enLocale, ar: arLocale, pt: ptLocale };

export function fDate(date, locale = 'en') {
  return format(new Date(date), 'dd MMMM yyyy', { locale: LOCALES[locale] });
}

export function fDateShort(date, locale = 'en') {
  return format(new Date(date), 'dd MMM yyyy', { locale: LOCALES[locale] })
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fTimestampNow() {
  return getTime(new Date());
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fParseDateTime(date, formatStr, locale = 'en') { // assuming backend date 2022-04-12T08:12
  try{
    const dateStr = date.slice(0,19);
    const parsed = parseISO(dateStr);
    return format(parsed, formatStr || 'dd/MM/yyyy HH:mm', { locale: LOCALES[locale] });
  } catch(e){
    return date;
  }
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}
export function fDisplay(date, formatStr) {
  return format(new Date(date), formatStr);
}
export function fCal(date) {
  const timezone =  (format(new Date(date), 'OOOO')).slice(3);
  const dateTimeEST = format(new Date(date), `yyyy-MM-dd'T'00:00:00${timezone}'`);
  return dateTimeEST;
}

export function fToNextMonthCal(date, skipFormat) {
  const nextMonth = addMonths(new Date(date), 1);
  if(skipFormat) return nextMonth;
  return fCal(nextMonth);
}

export function fToNextWeekCal(date, skipFormat) {
  const nextWeek = addWeeks(new Date(date), 1);
  if(skipFormat) return nextWeek;
  return fCal(nextWeek);
}

export function fToNextDayCal(date, skipFormat) {
  const nextDay = addDays(new Date(date), 1);
  if(skipFormat) return nextDay;
  return fCal(nextDay);
}


export function fFromTo(from, to) {
  return `${format(from, `dd-MM-yyyy`)} to ${format(to, `dd-MM-yyyy`)}`
}
